const Languages = {
    pt: {
        translation: {
            // TELAS
            "Painel": "Painel",
            "Cadastros": "Cadastros",
            "Projetos": "Projetos",
            "Parceiros": "Parceiros",
            "Usuários": "Usuários",
            "Ramo de Atividade": "Ramo de Atividade",
            "Bem_vindo": "Bem Vindo",
            "Perfil": "Perfil",
            "Editar Perfil": "Editar Perfil",
            "Sair": "Sair",
            "Portugues": "Português",
            "Ingles": "Inglês",

            // CAMPOS
            
            "Nome": "Nome",
            "Telefone": "Telefone",
            "E-mail": "E-mail",
            "Estados": "Estados",
            "cidade": "cidade",
            "Tipo de Parceiro": "Tipo de Parceiro",
            "Cargo do Parceiro": "Cargo do Parceiro",

            "Nome da Igreja": "Nome da Igreja",
            "Nº de Membros": "Nº de Membros",
            "É Sede?": "É Sede?",

            "Cargo": "Cargo",
            "Nome da Empresa": "Nome da Empresa",
            "Rumo da Atividade": "Rumo da Atividade",
            
            "Tipo": "Tipo",
            "Área de Distribuição": "Área de Distribuição",
            "Nº de Templos": "Nº de Templos",
            "Contato do Parceiro": "Contato do Parceiro",
            "Cargo do Contato no Parceiro": "Cargo do Contato no Parceiro",
            "Tiragem, Alcance e etc": "Tiragem, Alcance e etc",

            "Pessoa Física": "Pessoa Física",
            "Empresa": "Empresa",
            "Igreja": "Igreja",
            
            "pessoa_fisica": "Pessoa Física",
            "empresa": "Empresa",
            "midia": "Media",
            "igreja": "Igreja",

            'influencer_cantor': "Influencer, cantor ou outra personalidade gospel",
            'empresario': "Empresário",
            'pastor': "Pastor (não famoso)",
            'politico': "Político",

            "Influencer, cantor ou outra personalidade gospel": "Influencer, cantor ou outra personalidade gospel",
            "Empresário": "Empresário",
            "Pastor (não famoso)": "Pastor (não famoso)",
            "Político": "Político",

            "Campo obrigatorio": "Campo obrigatório",
            'E-mail inválido': 'E-mail inválido',
            'Acessar': 'Acessar',
            "Codigo": "Código",
            "Senha": "Senha",


            // CRUDS
            "Registro Salvo com Sucesso": "Registro Salvo com Sucesso",
            'Registro Atualizado com Sucesso': 'Registro Atualizado com Sucesso',
            "Deseja deletar este registro?": "Deseja deletar este registro?",
            "Registro deletado com sucesso.": "Registro deletado com sucesso.",
            "Novo": "Novo",
            "Salvar": "Salvar",
            "Cancelar": "Cancelar",
            "Pesquisar": "Pesquisar",

            // PAGINATION
            "Monstrando": "Monstrando",
            "de": "de",
            "registros": "registros",
            "Próxima": "Próxima",
            "Anterior": "Anterior",

            "Acoes": "Ações",

            //Ellson
            //pastaEmpresasParceiras

            'Selecione o Parceiro': 'Selecione o Parceiro',
            'Distribuição de Material': 'Distribuição de Material',
            'Exibição de trailler': 'Exibição de trailler',
            'QR Code': 'QR Code',
            'Panfletagem': 'Panfletagem',
            'Exposição de Banner': 'Exposição de Banner',
            'Exibição de VT': 'Exibição de VT',
            'Testemunhal': 'Testemunhal',
            'Data de inicio': 'Data de inicio',
            'Data Fim': 'Data Fim',
            'Nº de dias de duvulgação efetiva:': 'Nº de dias  de duvulgação efetiva:',
            'Nº de clientes abordados:': 'Nº de clientes abordados:',
            'Médias de pessoas impactadas:': 'Médias de pessoas impactadas:',

            //pastaEventos

            'Café com Pastores': 'Café com Pastores',
            'Reunião de Liderança': 'Reunião de Liderança',
            'Eventos com cantores': 'Eventos com cantores',
            'Outros eventos': 'Outros eventos',
            'Conselho de Pastores': 'Conselho de Pastores',
            'Encontro de Jovens': 'Encontro de Jovens',
            'Evento Infantil': 'Evento Infantil',
            'Data da Ação': 'Data da Ação',
            'Público Médio presente?': 'Público Médio presente?',
            'Havia Transmissão Online?': 'Havia Transmissão Online?',
            'Sim': 'Sim',
            'Não': 'Não',
            'Público online total estimado': 'Público online total estimado',
            'Autoridades Cristãs Presentes?': 'Autoridades Cristãs Presentes?',
            'Nº Estimado de Autoridades': 'Nº Estimado de Autoridades',
            'Autoridades Políticas Presentes?': 'Autoridades Políticas Presentes?',
            'Nº Estimado de Políticos': 'Nº Estimado de Políticos',

            //pastaMidiaDigital

            'Nicho Gospel:': 'Nicho Gospel:',
            "Banner superior Home Page": "Banner superior Home Page",
            "Banner lateral Home Page": "Banner lateral Home Page",
            "banner rodapé": "banner rodapé",
            "Post Instagram/Facebook": "Post Instagram/Facebook",
            "Divulgação no Youtube": "Divulgação no Youtube",
            'Apenas números': 'Apenas números',
            "Live com entrevista a diretores": "Live com entrevista a diretores",
            "Testimonial/Divulgação": "Testimonial/Divulgação",
            "Reels": "Reels",
            "Stories": "Stories",
            'data:': 'data:',
            'Houve entrevista?': 'Houve entrevista?',
            "Pastores": "Pastores",
            "Líderes em geral": "Líderes em geral",
            "Membros": "Membros",
            "Empresários": "Empresários",
            "Igreja Católica": "Igreja Católica",

            //pastaMidiaEletronica

            'Buscar em Parceiros': 'Buscar em Parceiros',
            'Tipo de conteúdo:': 'Tipo de conteúdo:',
            'Anúncio': 'Anúncio',
            'Reportagem': 'Reportagem',
            'Merchandising': 'Merchandising',
            '90 segundos': '90 segundos',
            '60 segundos': '60 segundos',
            '45 segundos': '45 segundos',
            '30 segundos': '30 segundos',
            '15 segundos': '15 segundos',
            'Buscar em Press Releases': 'Buscar em Press Releases',
            'Houve cobrança?': 'Houve cobrança?',
            'Se sim, qual valor?': 'Se sim, qual valor?',

            //pastaMidiaImpressa

            "anuncio": "anuncio",
            'Total de Anúncios': 'Total de Anúncios',
            'Mês de Circulação': 'Mês de Circulação',

            //pastaReunioesFaceToFace

            'Parceiro anfitrião/convidado:': 'Parceiro anfitrião/convidado:',
            'Exibição de Material': 'Exibição de Material',
            'Exibição de Testemunhal': 'Exibição de Testemunhal',
            'Marcadores de página ou outro material gráfico': 'Marcadores de página ou outro material gráfico',
            'Data da Reunião': 'Data da Reunião',

            //pastaUploadImages

            'Carregar de Imagem': 'Carregar de Imagem',

            //pastaVisitaIgrejas

            'Igreja Parceira': 'Igreja Parceira',
            'Qual reunião?': 'Qual reunião?',
            'Culto Principal de domingo': 'Culto Principal de domingo',
            'Culto de Jovens': 'Culto de Jovens',
            'Reunião das Mulheres': 'Reunião das Mulheres',
            'Outros Eventos': 'Outros Eventos',
            'Reunião de Lideres (apenas desta igreja)': 'Reunião de Lideres (apenas desta igreja)',
            'Escola Biblica Dominical': 'Escola Biblica Dominical',
            'Reunião dos Homens': 'Reunião dos Homens',
            'Nº cultos atendidos': 'Nº cultos atendidos',
            'Nº total de pessoas impactadas': 'Nº total de pessoas impactadas',
            'Stage Mic': 'Stage Mic',
            'QR Code no telão': 'QR Code no telão',
            'Apresentação pelo pastor': 'Apresentação pelo pastor',
            'Data da Visita': 'Data da Visita',


            //index.js - pasta acoes

            'Tipo de Ação': 'Tipo de Ação',
            'Visita às Igrejas': 'Visita às Igrejas',
            'Ações presenciais em empresas parceiras': 'Ações presenciais em empresas parceiras',
            'Reuniões face-to-face': 'Reuniões face-to-face',
            'Eventos': 'Eventos',
            'Mídia Impressa': 'Mídia Impressa',
            'Mídia Eletrônica (Rádio ou TV)': 'Mídia Eletrônica (Rádio ou TV)',
            'Mídia Digital': 'Mídia Digital',


        }
    },
    en: {
        translation: {
            // TELAS
            "Painel": "Panel",
            "Cadastros": "Creates",
            "Projetos": "Projects",
            "Parceiros": "Partners",
            "Usuários": "Users",
            "Ramo de Atividade": "Branch of Activity",
            "Bem_vindo": "Welcome",
            "Perfil": "Profile",
            "Editar Perfil": "Edit Profile",
            "Sair": "Logout",
            "Portugues": "Portuguese",
            "Ingles": "English",

            // CAMPOS
            "Nome": "Name",
            "Telefone": "Telephone",
            "E-mail": "E-mail",
            "Estados": "States",
            "Cidade": "City",
            "Tipo de Parceiro": "Partners Types",
            "Cargo do Parceiro": "Partner's Position",
            
            "Nome da Igreja": "Church name",
            "Nº de Membros": "Numbers Members",
            "É Sede?": "Its Thirst?",

            "Cargo": "Office",
            "Nome da Empresa": "Company Name",
            "Rumo da Atividade": "Activity Course",
            
            "Tipo": "Type",
            "Área de Distribuição": "Distribution Area",
            "Nº de Templos": "Number Temples",
            "Contato do Parceiro": "Partner Contact",
            "Cargo do Contato no Parceiro": "Position of the Contact at the Partner",
            "Tiragem, Alcance e etc": "Draw, Reach, etc.",

            "Pessoa Física": "Physical Person",
            "Empresa": "Company",
            "Igreja": "Crurch",

            "pessoa_fisica": "Physical Person",
            "empresa": "Company",
            "igreja": "Crurch",

            'influencer_cantor': "Influencer, singer or other gospel personality",
            'empresario': "Businessperson",
            'pastor': "Shepheard",
            'politico': "Political",

            "Influencer, cantor ou outra personalidade gospel": "Influencer, singer or other gospel personality",
            "Empresário": "Businessperson",
            "Pastor (não famoso)": "Shepheard",
            "Político": "Political",

            "Campo obrigatorio": "Required field",
            'E-mail inválido': 'Invalid Email',
            'Acessar': 'Sign in',
            "Codigo": "Code",
            "Senha": "Password",

            // CRUDS
            "Registro Salvo com Sucesso": "Register Save with Success",
            'Registro Atualizado com Sucesso': 'Register Update with Success',
            "Deseja deletar este registro?": "Wish you to delete this register?",
            "Registro deletado com sucesso.": "Register deleted with Success.",
            "Novo": "New",
            "Salvar": "Save",
            "Cancelar": "Cancel",
            "Pesquisar": "Search",

            // PAGINATION
            "Monstrando": "Show",
            "de": "to",
            "registros": "registers",
            "Próxima": "Next",
            "Anterior": "Prev",

            // 
            "Acoes": "Actions",

            //Ellson
            //pastaEmpresasParceiras

            'Selecione o Parceiro': 'Select Partner',
            'Distribuição de Material': 'Material Distribution',
            'Exibição de trailler': 'Trailler view',
            'QR Code': 'QR Code',
            'Panfletagem': 'Pamphleting',
            'Exposição de Banner': 'Banner Exhibition',
            'Exibição de VT': 'VT display',
            'Testemunhal': 'Witness',
            'Data de inicio': 'Start date',
            'Data Fim': 'End Date',
            'Nº de dias de duvulgação efetiva:': 'Number of days of effective disclosure:',
            'Nº de clientes abordados:': 'Number of customers approached:',
            'Médias de pessoas impactadas:': 'Averages of people impacted:',

            //pastaEventos

            'Café com Pastores': 'Coffee with Pastors',
            'Reunião de Liderança': 'Leadership Meeting',
            'Eventos com cantores': 'Events with singers',
            'Outros eventos': 'Other events',
            'Conselho de Pastores': 'Council of Pastors',
            'Encontro de Jovens': 'Youth Encounter',
            'Evento Infantil': 'Childrens Event',
            'Data da Ação': 'Action Date',
            'Público Médio presente?': 'Average audience present?',
            'Havia Transmissão Online?': 'Was there Online Streaming?',
            'Sim': 'Yes',
            'Não': 'No',
            'Público online total estimado': 'Estimated total online audience',
            'Autoridades Cristãs Presentes?': 'Christian Authorities Present?',
            'Nº Estimado de Autoridades': 'Estimated Number of Authorities',
            'Autoridades Políticas Presentes?': 'Political Authorities Present?',
            'Nº Estimado de Políticos': 'Estimated Number of Politicians',

            //pastaMidiaDigital

            'Nicho Gospel:': 'Gospel Niche:',
            "Banner superior Home Page": "Top Home Page Banner",
            "Banner lateral Home Page": "Home Page Side Banner",
            "Banner rodapé": "Footer Banner",
            "Post Instagram/Facebook": "Instagram/Facebook Post",
            "Divulgação no Youtube": "Disclosure on Youtube",
            'Apenas números': 'Numbers only',
            "Live com entrevista a diretores": "Live with an interview with directors",
            "Testimonial/Divulgação": "Testimonial/Disclosure",
            "Reels": "Reels",
            "Stories": "Stories",
            'data:': 'date:',
            'Houve entrevista?': 'Was there an interview?',
            "Pastores": "Shepherds",
            "Líderes em geral": "Leaders in general",
            "Membros": "Members",
            "Empresários": "Entrepreneurs",
            "Igreja Católica": "Catholic Church",

            //pastaMidiaEletronica

            'Buscar em Parceiros': 'Search Partners',
            'Tipo de conteúdo:': 'Content-Type:',
            'Anúncio': 'Announcement',
            'Reportagem': 'Report',
            'Merchandising': 'Merchandising',
            '90 segundos': '90 seconds',
            '60 segundos': '60 seconds',
            '45 segundos': '45 seconds',
            '30 segundos': '30 seconds',
            '15 segundos': '15 seconds',
            'Buscar em Press Releases': 'Search Press Releases',
            'Houve cobrança?': 'Was there a charge?',
            'Se sim, qual valor?': 'If yes, what value?',

            //pastaMidiaImpressa

            "anuncio": "announcement",
            'Total de Anúncios': 'Total Ads',
            'Mês de Circulação': 'Circulation Month',

            //pastaReunioesFaceToFace

            'Parceiro anfitrião/convidado:': 'Partner Host/Guest:',
            'Exibição de Material': 'Material Display',
            'Exibição de Testemunhal': 'Testimony Display',
            'Marcadores de página ou outro material gráfico': 'Bookmarks or other graphic material',
            'Data da Reunião': 'Meeting Date',

            //pastaUploadImages

            'Carregar de Imagem': 'Load Image',

            //pastaVisitaIgrejas

            'Igreja Parceira': 'Partner Church',
            'Qual reunião?': 'Which meeting?',
            'Culto Principal de domingo': 'Sunday Main Service',
            'Culto de Jovens': 'Youth Worship',
            'Reunião das Mulheres': 'Womens Meeting',
            'Reunião de Lideres (apenas desta igreja)': 'Leadership meeting (for this church only)',
            'Escola Biblica Dominical': 'Sunday Bible School',
            'Reunião dos Homens': 'Mens Meeting',
            'Nº cultos atendidos': 'Number of services attended',
            'Nº total de pessoas impactadas': 'Total number of people impacted',
            'Stage Mic': 'Stage Mic',
            'QR Code no telão': 'QR Code on the big screen',
            'Apresentação pelo pastor': 'Presentation by the pastor',
            'Data da Visita': 'Date of Visit',
            'Outros Eventos': 'Other Events',


            //index.js - pasta acoes

            'Tipo de Ação': 'Action Type',
            'Visita às Igrejas': 'Visit to Churches',
            'Ações presenciais em empresas parceiras': 'On-site actions at partner companies',
            'Reuniões face-to-face': 'Face-to-Face Meetings',
            'Eventos': 'Events',
            'Mídia Impressa': 'Print Media',
            'Mídia Eletrônica (Rádio ou TV)': 'Electronic Media (Radio or TV)',
            'Mídia Digital': 'Digital Media',

        }
    }
};

export default Languages