import React from 'react';
import CrudSmall from '../../../../components/CrudSmall';
import { ErrorMessage, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { InputCheckbox, InputMask, InputRadio, InputText, Select } from '../../../../components/FormHelper/Input';


export default function Estados(props) {
    const { t } = useTranslation();

    return (
            <CrudSmall
                title={props.title}
                titleBtnNew="Novo Estado"
                endPoint="estados"
                id={props.values.id}
                nameField="estados"
                setFieldValue={props.setFieldValue}
                emptyObject={{
                    nome: '',
                }}
                thead={() => (
                    <>
                        <tr>
                            <th>Nome</th>
                            <th>Ações</th>
                        </tr>
                    </>
                )}
                Tbody={({ item }) => (
                    <>
                        <th>{item.nome}</th>
                    </>
                )}
                FormWrapper={() => (
                    <>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Field
                                    required
                                    id="nome"
                                    label={t('Nome do Estado')}
                                    name="nome"
                                    component={InputText}
                                    error={<ErrorMessage name="nome" />}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            />
    )
}