import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputCheckbox, InputMask, InputRadio, InputText, Select } from '../../../components/FormHelper/Input';
import SelectCidades from '../../../components/FormHelper/SelectCidades';
import SelectEstados from '../../../components/FormHelper/SelectEstados';
import CrudSmall from '../../../components/CrudSmall';
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from 'shards-react';
import { Grid } from '@mui/material';
import RevistaImpressa from './RevistaImpressa';
import RevistaDigital from './RevistaDigital';
import Radio from './Radio';
import CanalTV from './CanalTV';
import Api from '../../../core/api';
import Message from 'src/core/Message';
import Util from 'src/core/Util';

export default function MeioComunicacao(props) {
    const { t } = useTranslation();

    const[open, setOpen] = useState(false);
    const [isEditIndex, setIsEditIndex] = useState(null);
    const[list, setList] = useState([]);
    const [data, setData] = useState({
        id: '',
        parceiro_id: props.values.id ? props.values.id : '',
        tipo_veiculo: '',
        nicho: '',
        tiragem: '',
        circulacao: '',
        classe_a: false,
        classe_b: false,
        classe_c: false,
        classe_d: false,
        classe_e: false,
        feminino: '',
        masculino: '',
        idade_predominante: '',
        url: '',
        visita_por_mes: '',
        acesso_por_mes: '',
        nome_programa: '',
        ouvintes_mes: '',
        e_webraio: '',
        nome_contato: '',
        cargo_contato: '',
        telefone_parceiro: '',
        email_parceiro: '',
        estado_parceiro: '',
        cidade_parceiro: '',
        audiencia_estimada: '',
        public_potencia: '',
        transmissao_online: '',
        alcances: '', // estados: '',
        maior_audiencia: '', //- estados: '',
        audiencia_internacional: '',//- paises: '',
        cidades: []
    });
    

    async function getData() {
        if(props.values.id) {
            const response = await Api('get', `veiculo`, '', {
                params: {
                    parceiro_id: props.values.id,
                }
            }); 
            setList(response.data);
        }
    }

    async function handleSubmit(values) {
        if(props.values.id) {
            if(values.id) {
                await Api('put', `veiculo/${values.id}`, '', {
                    ...values,
                    parceiro_id: props.values.id
                });
                Message.success(t('Registro Atualizado com Sucesso.'));
                getData();
            } else {
                await Api('post', `veiculo`, '', {
                    ...values,
                    parceiro_id: props.values.id
                });
                Message.success(t('Registro Criado com Sucesso.'));
                getData();
            }

        } else {

            if(values.id) {
                await Api('post', `veiculo`, '', {
                    ...values,
                    parceiro_id: props.values.id
                });
                Message.success(t('Registro Criado com Sucesso.'));
                getData();
            } else {
                let lis = list;
                lis.push(values);
                setList([...lis]);
                props.setFieldValue('veiculos', lis);
                Message.success(t('Registro Criado com Sucesso.'));
            }

        }
        setIsEditIndex(null);
        setData({})
        setOpen(false);
    }

    async function editar(values, index) {
        if(props.values.id) {
            const response = await Api('get', `veiculo/${values.id}`, '', {});
            setData(response.data);
        } else {
            setData(list[index]);
        }
        setOpen(true);
    }

    async function excluir(values, idx) {
        if(props.values.id) {
            await Api('delete', `veiculo/${values.id}`, '', {});
            getData();
            Message.success("Veículo Deletado com Sucesso");
        } else {
            let lis = list.filter((item, index) => index !== idx);
            setList(lis);
        }
    }

    useEffect(() => {
        getData();
    }, []);


    return (
        <>
            <br /><br /><br /><br />
            <Button onClick={() => setOpen(true)}>Novo Meio de Comunicação</Button>
            <Modal open={open} toggle={() => setOpen(false)} size="lg">
                <ModalHeader>Novo Ramo de Atividade</ModalHeader>
                <ModalBody style={{ height: '450px', overflowY: 'auto' }}>
                    <Formik
                        enableReinitialize
                        initialValues={data}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSubmit(values);
                                actions.setSubmitting(false);
                            }, 1000);
                        }}
                    >
                        {({ values, handleSubmit, setFieldValue }) => (
                            <form>
                                {/* {JSON.stringify(values, null, 2)} */}
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Field 
                                            required
                                            column={4}
                                            id="tipo_veiculo"
                                            label={t('Tipo de Veículo')}
                                            name="tipo_veiculo"
                                            component={InputRadio}
                                            callBack={(e) => {
                                                setFieldValue('url', '');
                                                setFieldValue('visita_por_mes', '');
                                                setFieldValue('acesso_por_mes', '');
                                                setFieldValue('classe_a', '');
                                                setFieldValue('classe_b', '');
                                                setFieldValue('classe_c', '');
                                                setFieldValue('classe_d', '');
                                                setFieldValue('classe_e', '');
                                                setFieldValue('classe_e', '');
                                                setFieldValue('feminino', '');
                                                setFieldValue('masculino', '');
                                                setFieldValue('idade_predominante', '');
                                                setFieldValue('cidades', []);
                                                
                                            }}
                                            list={[
                                                { value: 'revista_impressa', label: t('Revista Impressa') },
                                                { value: 'revista_digital', label: t('Revista Digital') },
                                                { value: 'website', label: t('Website') },
                                                { value: 'jornal_impresso', label: t('Jornal impresso') },
                                                { value: 'jornal_digital', label: t('Jornal Digital') },
                                                { value: 'canal_tv', label: t('Canal de TV') },
                                                { value: 'radio', label: t('Rádio') }
                                            ]}
                                            error={<ErrorMessage name="tipo_veiculo" />}
                                        />
                                    </Grid>
                                </Grid>
                                {/* {JSON.stringify(values, null, 2)} */}
                                {['revista_impressa', 'jornal_impresso'].includes(values.tipo_veiculo) && 
                                    <RevistaImpressa values={values} setFieldValue={setFieldValue} />
                                }

                                {['revista_digital', 'website', 'jornal_digital'].includes(values.tipo_veiculo) && 
                                    <RevistaDigital values={values} setFieldValue={setFieldValue}/>
                                }

                                {['radio'].includes(values.tipo_veiculo) && 
                                    <Radio values={values} setFieldValue={setFieldValue}/>
                                }

                                {['canal_tv'].includes(values.tipo_veiculo) && 
                                    <CanalTV values={values} setFieldValue={setFieldValue}/>
                                }
                                <div style={{ textAlign: 'right' }}>
                                    <Button onClick={handleSubmit}>Salvar</Button>
                                </div>
                            </form>
                        )}
                    </Formik>

                </ModalBody>
            </Modal>

            <table className="table mb-0">
                <thead className="bg-light">
                    <tr>
                        <th>Tipo de Veículo</th>
                        {/* <th>Nicho</th> */}
                        <th>Tiragem</th>
                        <th width="20%">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, index) => (
                        <tr key={index}>
                            <td>{Util.getTipoComunicacao(item.tipo_veiculo)}</td>
                            {/* <td>{item.nicho === 'gostel' ? 'Gospel' : 'Não Gospel'}</td> */}
                            <td>{item.tiragem}</td>
                            <td>
                                <Button onClick={() => editar(item, index)}>Editar</Button>&nbsp;
                                <Button onClick={() => excluir(item, index)}>Excluir</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </>
    );
}