import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import { InputMask, InputRadio, InputText } from '../../../components/FormHelper/Input';
import { useTranslation } from 'react-i18next';
import SelectCidades from '../../../components/FormHelper/SelectCidades';
import SelectEstados from '../../../components/FormHelper/SelectEstados';
import CrudSmall from '../../../components/CrudSmall';

export default function Pastor(props) {

    const { t } = useTranslation();
    const [list, setList] = useState([]);

    return (
        <>
            <Grid container spacing={2}>
                <hr />
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="nome_parceiro"
                        label={t('Nome do Parceiro')}
                        name="nome_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="nome_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        required
                        id="telefone_parceiro"
                        label={t('Telefone')}
                        name="telefone_parceiro"
                        component={InputMask}
                        mask="(##) ####.####"
                        error={<ErrorMessage name="telefone_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="email_parceiro"
                        label={t('E-mail')}
                        name="email_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="email_parceiro" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="estado_parceiro"
                        label={t('Estados')}
                        name="estado_parceiro"
                        cityName="cidade_parceiro"
                        component={SelectEstados}
                        list={[]}
                        error={<ErrorMessage name="estado_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="cidade_parceiro"
                        label={t('Cidade')}
                        name="cidade_parceiro"
                        component={SelectCidades}
                        estado={props.values.estado_parceiro}
                        error={<ErrorMessage name="cidade_parceiro" />}
                    />
                </Grid>
            </Grid>
            <br /><br />
            <CrudSmall
                title="Igrejas"
                titleBtnNew="Nova Igreja"
                endPoint="igreja"
                id={props.values.id}
                nameField="igrejas"
                param="parceiro_id"
                setFieldValue={props.setFieldValue}
                emptyObject={{
                    nome: '',
                    numero_membros: '',
                    e_sede: '',
                }}
                thead={() => (
                    <>
                        <tr>
                            <th>Nome</th>
                            <th>Nº Membros</th>
                            <th>Nº Templos</th>
                            <th>Ações</th>
                        </tr>
                    </>
                )}
                Tbody={({ item }) => (
                    <>
                        <th>{item.nome}</th>
                        <th>{item.numero_membros}</th>
                        <th>{item.e_sede}</th>
                    </>
                )}
                FormWrapper={() => (
                    <>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Field
                                    required
                                    id="nome"
                                    label={t('Nome da Igreja')}
                                    name="nome"
                                    component={InputText}
                                    error={<ErrorMessage name="nome" />}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Field 
                                    required
                                    id="numero_membros"
                                    label={t('Nº de Membros')}
                                    pattern="[0-9]*"
                                    name="numero_membros"
                                    component={InputText}
                                    error={<ErrorMessage name="numero_membros" />}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Field 
                                    required
                                    column={6}
                                    id="e_sede"
                                    label={t('É Sede?')}
                                    name="e_sede"
                                    component={InputRadio}
                                    list={[
                                        { value: 'regional', label: 'Regional' },
                                        { value: 'estadual', label: 'Estadual' },
                                        { value: 'nacional', label: 'Nacional' },
                                        { value: 'nao_e_sede', label: 'Não é sede' }
                                    ]}
                                    error={<ErrorMessage name="cargo_parceiro" />}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            />
        </>
    );
}