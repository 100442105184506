import { t } from 'i18next';
import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { Button } from 'shards-react';

export default function Grid(props) {
    
    const FieldsHtml = props.fieldsHtml;

    return (
        <table className="table mb-0">
            <thead className="bg-light">
                <tr>
                    {props.fields.map((item, index) => (
                        <th key={index} >{item.label}</th>
                    ))}
                    {props.enableBtnEdit && props.enableBtnEdit &&
                        <th style={{ width: '10%' }}>{t('Acoes')}</th>
                    }
                </tr>
            </thead>
            <tbody>
                {props.list && props.list.map((item, idx) => (
                    <tr key={idx}>
                        {props.fieldsHtml && <FieldsHtml {...props} item={item}  />}
                        {!props.fieldsHtml && 
                            <>
                                {props.fields.map(i => (
                                    <td className={i.classBody || ''}>{item[i.name]}</td>
                                ))}
                            </>
                        }
                        {props.enableBtnEdit && props.enableBtnEdit &&
                            <td style={{ width: '12%' }}>
                                {props.enableBtnEdit && 
                                    <Button size="sm" theme="primary" onClick={() => {
                                        if(props.handleCustomEdit) {
                                            props.handleCustomEdit(item)
                                        } else {
                                            props.handleEdit(item)
                                        }
                                    }}>
                                        {props.titleBtnEdit ? props.titleBtnEdit : <FiEdit color="#FFF" />}
                                    </Button>
                                }
                                &nbsp;
                                {props.enableBtnDelete && 
                                    <Button size="sm" theme="danger" onClick={() => props.handleDelete(item)}>
                                        <MdClose  color="#FFF"/>
                                    </Button>
                                }
                            </td>
                        }
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

Grid.defaultProps = {
    list: [],
    fields: [],
    fieldsHtml: null,
    handleEdit: null,
    handleDelete: null,
    enableBtnEdit: true,
    enableBtnDelete: true,
    handleCustomEdit: null,
    titleBtnEdit: '',
};

