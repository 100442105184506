import { FormLabel, Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectCidades from '../../../components/FormHelper/SelectCidades';
import SelectEstados from '../../../components/FormHelper/SelectEstados';
import { InputMask, InputRadio, InputText } from '../../../components/FormHelper/Input';

export default function Politico(props) {
    const { t } = useTranslation();
    return (
        <>
            <Grid container spacing={2}>
                <hr />
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="nome_parceiro"
                        label={t('Nome do Parceiro')}
                        name="nome_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="nome_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        required
                        id="telefone_parceiro"
                        label={t('Telefone')}
                        name="telefone_parceiro"
                        component={InputMask}
                        mask="(##) ####.####"
                        error={<ErrorMessage name="telefone_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="email_parceiro"
                        label={t('E-mail')}
                        name="email_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="email_parceiro" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="estado_parceiro"
                        label={t('Estados')}
                        name="estado_parceiro"
                        cityName="cidade_parceiro"
                        component={SelectEstados}
                        list={[]}
                        error={<ErrorMessage name="estado_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="cidade_parceiro"
                        label={t('Cidade')}
                        name="cidade_parceiro"
                        component={SelectCidades}
                        estado={props.values.estado_parceiro}
                        error={<ErrorMessage name="cidade_parceiro" />}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="cargo"
                        label={t('Cargo')}
                        name="cargo"
                        component={InputText}
                        error={<ErrorMessage name="cargo" />}
                    />
                </Grid>
                <FormLabel>
                    Redes Sociais
                </FormLabel>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="instagram"
                        label="Instagram"
                        name="instagram"
                        component={InputText}
                        error={<ErrorMessage name="instagram" />}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="facebook"
                        label="Facebook"
                        name="facebook"
                        component={InputText}
                        error={<ErrorMessage name="facebook" />}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="youtube"
                        label="Youtube"
                        name="youtube"
                        component={InputText}
                        error={<ErrorMessage name="youtube" />}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <FormLabel>
                    <br />
                    <strong>{t('Total de Seguidores')}</strong>
                </FormLabel>
                <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Field
                        required
                        id="instagram_seguidores"
                        label="Instagram"
                        pattern="[0-9]*"
                        name="instagram_seguidores"
                        component={InputText}
                        error={<ErrorMessage name="instagram_seguidores" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        required
                        id="facebook_seguidores"
                        label="Facebook"
                        pattern="[0-9]*"
                        name="facebook_seguidores"
                        component={InputText}
                        error={<ErrorMessage name="facebook_seguidores" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        required
                        id="youtube_seguidores"
                        label="Youtube"
                        pattern="[0-9]*"
                        name="youtube_seguidores"
                        component={InputText}
                        error={<ErrorMessage name="youtube_seguidores" />}
                    />
                </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                        <Field 
                            required
                            column={2}
                            id="bancada_evangelia"
                            label={t('Bancada evangélica?')}
                            name="bancada_evangelia"
                            component={InputRadio}
                            list={[
                                { value: 'sim', label: t('Sim') },
                                { value: 'nao', label: t('Não') },
                            ]}
                            error={<ErrorMessage name="bancada_evangelia" />}
                        />
                    </Grid>
            </Grid>
        </>
    );
}