import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';

export default function InputSearch(props) {
    const { t } = useTranslation();
    return (
        <form onSubmit={e => props.loadData(e)} className="form-inline mr-auto w-100 navbar-search">
            <div className="input-group">
                <input 
                    type="text" 
                    value={props.value}
                    onChange={e => props.handleText(e.target.value)}
                    className="form-control bg-light border-0 small"
                    placeholder={t("Pesquisar")} 
                    aria-label={t("Pesquisar")}
                    aria-describedby="basic-addon2" 
                />
                <div className="input-group-append">
                    <button className="btn btn-primary" onClick={() => props.loadData()} type="button">
                        <BsSearch color="#FFF" />
                    </button>
                </div>
            </div>
        </form>
    );
}