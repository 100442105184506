import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Pagination as Paginate } from 'react-laravel-paginex'

export default function Pagination(props) {

    const { t } = useTranslation();
    const options = {
        nextButtonText: t('Próxima'),
        prevButtonText: t('Anterior')
    };

    //return null;
    return (
        <>
            <Row style={{ padding: '5px' }}>
                <Col>
                    <Paginate 
                        {...props}
                        options={options}
                    />
                </Col>
                <Col style={{ textAlign: 'right' }}>
                    <p>{t("Monstrando")} {props.data.to || 0} {t('de')} {props.data.total || 0} {t('registros')}.</p>
                </Col>
            </Row>
        </>
    );
}