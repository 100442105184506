import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import AuthContext from "../../../../context/auth";
import { useTranslation } from "react-i18next";

export default function UserActions(props){
  
  const { setIsLogged, user, language, setLanguage } = useContext(AuthContext);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  function toggleUserActions() {
    setVisible(!visible);
  }

  function toggleUserActions2() {
    setVisible2(!visible2);
  }
  return (
    <>
      <NavItem tag={Dropdown} caret toggle={toggleUserActions2} >
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3" style={{ paddingTop: '15px', cursor: 'pointer' }}>
          <img
            className="user-avatar mr-2"
            src={require(`./../../../../images/${language}.png`)}
            alt="Idioma"
          />{" "}
          <span className="d-none d-md-inline-block">{language === 'pt' ? t('Portugues') : t('Ingles')}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={visible2}>
          <DropdownItem onClick={() => {
              localStorage.setItem('language', 'pt');
              setLanguage('pt');
            }} to="user-profile">
              {t('Portugues')}
          </DropdownItem>
          <DropdownItem onClick={() => {
              localStorage.setItem('language', 'en');
              setLanguage('en');
            }} to="edit-user-profile">
              {t('Ingles')}
          </DropdownItem>
          <DropdownItem divider />
        </Collapse>
      </NavItem>
      <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3"  style={{ cursor: 'pointer' }}>
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/0.jpg")}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">{user.name}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={visible}>
          {/* <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> {t('Perfil')}
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> {t('Editar Perfil')}
          </DropdownItem> */}
          
          <DropdownItem divider />
          <DropdownItem onClick={() => setIsLogged(false)} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> {t('Sair')}
          </DropdownItem>
        </Collapse>
      </NavItem>   
    </>
  );
}
