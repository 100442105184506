import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { InputCheckbox, InputMask, InputRadio, InputText, Select } from '../../../../components/FormHelper/Input';
import Estados from '../Estados';
import Paises from '../Paises';

export default function RevistaDigital(props) {
    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="url"
                        label={t('URL')}
                        
                        name="url"
                        component={InputText}
                        error={<ErrorMessage name="url" />}
                    />
                </Grid>
            </Grid>
            {/* <Grid container>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        column={3}
                        id="nicho"
                        label={t('Nicho')}
                        name="nicho"
                        component={InputRadio}
                        list={[
                            { value: 'gospel', label: t('Gospel') },
                            { value: 'nao_gospel', label: t('Não Gospel') }
                        ]}
                        error={<ErrorMessage name="nicho" />}
                    />
                </Grid>
            </Grid> */}
            
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        column={3}
                        id="visita_por_mes"
                        label={t('Visita por mês')}
                        name="visita_por_mes"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="visita_por_mes" />}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        column={3}
                        id="acesso_por_mes"
                        label={t('Acessos por mês')}
                        name="acesso_por_mes"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="acesso_por_mes" />}
                    />
                </Grid>
            </Grid>
            <Estados title="Estado de Maior Audiencia" values={props.values} />
            <h5>Audiencia Internacional</h5>
            <Paises values={props.values} />

            <h6>Perfil da audiência</h6>
            <hr />
            <h6>Classe Social:</h6>
            <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                    <Field 
                        required
                        id="classe_a"
                        label={t('A')}
                        name="classe_a"
                        component={InputCheckbox}
                        value="A"
                        error={<ErrorMessage name="classe_a" />}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <Field 
                        required
                        id="classe_b"
                        label={t('B')}
                        name="classe_b"
                        component={InputCheckbox}
                        value="B"
                        error={<ErrorMessage name="classe_b" />}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <Field 
                        required
                        id="classe_c"
                        label={t('C')}
                        name="classe_c"
                        component={InputCheckbox}
                        value="C"
                        error={<ErrorMessage name="classe_c" />}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <Field 
                        required
                        id="classe_d"
                        label={t('D')}
                        name="classe_d"
                        component={InputCheckbox}
                        value="D"
                        error={<ErrorMessage name="classe_d" />}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <Field 
                        required
                        id="classe_e"
                        label={t('E')}
                        name="classe_e"
                        component={InputCheckbox}
                        value="E"
                        error={<ErrorMessage name="classe_e" />}
                    />
                </Grid>
            </Grid>
            <h6>Sexo:</h6>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Field
                        required
                        id="feminino"
                        label={t('Feminino (%)')}
                        name="feminino"
                        component={InputText}
                        error={<ErrorMessage name="feminino" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field
                        required
                        id="masculino"
                        label={t('Masculino (%)')}
                        name="masculino"
                        component={InputText}
                        error={<ErrorMessage name="masculino" />}
                    />
                </Grid>
            </Grid>
                
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        column={3}
                        id="idade_predominante"
                        label={t('Idade predominante')}
                        name="idade_predominante"
                        component={InputRadio}
                        list={[
                            { value: '19_ou_menos', label: t('19 ou menos') },
                            { value: '20_29', label: t('20 a 29') },
                            { value: '30_39', label: t('30 a 39') },
                            { value: '40_49', label: t('40 a 49') },
                            { value: '50_59', label: t('50 a 59') },
                            { value: '60_mais', label: t('60 ou Mais') },
                        ]}
                        error={<ErrorMessage name="idade_predominante" />}
                    />
                </Grid>
            </Grid>
        </>
    );

}