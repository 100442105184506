import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shards-react';
import Api from '../../../core/api';
import { InputMask, InputRadio, InputText, Select, InputCheckbox, InputTextFormat } from '../../../components/FormHelper/Input';
import SelectAutoComplete from '../../../components/FormHelper/SelectAutoComplete';

export default function MidiaImpressa(props) {
    const { t } = useTranslation();
    const [parceiros, setParceiros] = useState([]);
    const [pautas, setPautas] = useState([]);

    async function getParceiros() {
        const response = await Api('get', 'parceiros/options', '', {});
        setParceiros(response.data);
    }

    async function getPautas() {
        const response = await Api('get', 'briefings/options', '', {});
        setPautas(response.data);
    }


    useEffect(() => {
        getParceiros();
        getPautas();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="parceiro_id"
                        label={t('Selecione o Parceiro')}
                        name="parceiro_id"
                        component={SelectAutoComplete}
                        endPoint="parceiros/options"
                        list={parceiros}
                        error={<ErrorMessage name="parceiro_id" />}
                    />
                </Grid>
            </Grid>   
            <hr className='auto' />

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="reuniao"
                        label={t('Tipo de conteúdo:')}
                        name="reuniao"
                        component={InputRadio}
                        list={[
                            { value: 'anuncio', label: t('Anúncio') },
                            { value: 'reportagem', label: t('Reportagem') },
                        ]}
                        error={<ErrorMessage name="reuniao" />}
                    />
                </Grid>
            </Grid>
            {props.values.reuniao === 'reportagem' &&
                <>
                    <hr className='auto' />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Field
                                required
                                id="pauta"
                                label={t('Selecione a Pauta')}
                                name="pauta"
                                component={Select}
                                list={pautas}
                                error={<ErrorMessage name="pauta" />}
                            />
                        </Grid>
                    </Grid>  
                    <hr className='auto' /> 
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Field 
                                required
                                id="tamanho"
                                label={t('Tamanho')}
                                name="tamanho"
                                component={InputRadio}
                                column={4}
                                list={[
                                    { value: 'duas_paginas_ou_mais', label: t('Duas páginas ou mais') },
                                    { value: 'meia_pagina', label: t('Meia Página') },
                                    { value: 'pagina_18', label: t('1/8 de Página') },
                                    { value: 'pargina_inteira', label: t('Página Inteira') },
                                    { value: 'pagina_14', label: t('1/4 de Página') },
                                    { value: 'outros', label: t('Outros') },                                    

                                ]}
                                error={<ErrorMessage name="reuniao" />}
                            />
                        </Grid>
                    </Grid>
                </>
            }

            {props.values.reuniao === 'anuncio' &&
                <> 
                    <hr className='auto' /> 
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Field 
                                required
                                id="tipo_anuncio"
                                label={t('Tipo de anúncio:')}
                                name="tipo_anuncio"
                                component={InputRadio}
                                column={4}
                                list={[
                                    { value: 'contracapa', label: t('Contracapa (frente ou verso)') },
                                    { value: 'pargina_inteira', label: t('Página Inteira de destaque (primeira ou última)') },
                                    { value: 'pagina_interna_esq', label: t('Página Interna Simples (Esq)') },
                                    { value: 'pagina_dupla', label: t('Página Dupla') },
                                    { value: 'pagina_18', label: t('1/8 de Página') },
                                    { value: 'capa_verso', label: t('Capa Verso') },
                                    { value: 'pagina_interna_dir', label: t('Página Interna Simples (Dir)') },
                                    { value: 'meia_pagina', label: t('Meia Página') },
                                    { value: 'pagina_14', label: t('1/4 de Página') },
                                    { value: 'outros', label: t('Outros') }
                                ]}
                                error={<ErrorMessage name="tipo_anuncio" />}
                            />
                        </Grid>
                    </Grid>
                </>
            }
            
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Field 
                        required
                        id="total_insercoes"
                        label={t('Total de inserções')}
                        name="total_insercoes"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="total_insercoes" />}
                    />
                </Grid>
            </Grid>

            {/* aleterações n° 21,22 e 23 */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <label className='line-title'>Período de Circulação:</label>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="data_inicio"
                        label={t('Data de inicio')}
                        name="data_inicio"
                        mask="##/##/####"
                        component={InputMask}
                        error={<ErrorMessage name="data_inicio" />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="data_fim"
                        label={t('Data Fim')}
                        name="data_fim"
                        pattern="[0-9]*"
                        mask="##/##/####"
                        component={InputMask}
                        error={<ErrorMessage name="data_fim" />}
                    />
                </Grid>
            </Grid>
            {/* fim aleterações n° 21,22 e 23 */}

                {/* alterações n° 24 e 25
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="houve_cobranca"
                        label={t('Houve cobrança?')}
                        name="houve_cobranca"
                        component={InputRadio}
                        list={[
                            { value: 'sim', label: t('Sim') },
                            { value: 'nao', label: t('Não') }
                        ]}
                        error={<ErrorMessage name="houve_cobranca" />}
                    />
                </Grid>
                <hr className='auto' />
                {props.values.houve_cobranca === 'sim' && 
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="valor_cobranca"
                            label={t('Se sim, qual valor?')}
                            name="valor_cobranca"
                            component={InputTextFormat}
                            error={<ErrorMessage name="valor_cobranca" />}
                        />
                    </Grid>
                </Grid>
            }
            fim alterações n° 24 e 25*/}
        </>
    );
}