import React from 'react';
import * as yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import FormButtons from '../FormHelper/FormButtons';

function Form(props){
    return (
        <Formik
            enableReinitialize
            initialValues={props.emptyObject}
            validationSchema={yup.object().shape({...props.validation(yup)})}
            onSubmit={props.handleSubmit}
        >
            {(objects) => {
                console.log(objects);
                return (
                <form>
                    {props.enableBtnTop && props.enableBtns && <FormButtons 
                        {...props} 
                        handleSave={objects.handleSubmit} 
                    />}
                    <props.FormWrapper 
                        ErrorMessage={ErrorMessage} 
                        Field={Field} 
                        view={props.view} 
                        loadFile={props.loadFile}
                        {...objects}
                        {...props}
                        handleSubmitForm={objects.handleSubmit}
                        handleCancelForm={objects.handleReset}
                    />
                    {props.enableBtns && <FormButtons 
                        {...props} 
                        handleSave={objects.handleSubmit} 
                    />}
                </form>
                );
            }}
        </Formik>
    );
}

Form.defaultProps = {
    FormWrapper: null,
    enableBtns: true,
    loadFile: null,
    enableBtnTop: false
}

export default Form;