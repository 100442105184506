import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AuthContext from "../context/auth";

import routes from "../routes";
import withTracker from "../withTracker";
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import Languages from "../core/Language";

export default function MainRouter() {

    const { isLogged, language } = useContext(AuthContext);
    i18n
        .use(initReactI18next).init({
        resources: Languages,
        lng: language,
        fallbackLng: language,
        interpolation: { escapeValue: false }
    });

    return (
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
            <div>
                {routes.map((route, index) => {
                return (
                    <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={withTracker(props => {
                        
                        if(!isLogged && props.match.path !== '/login') {
                            return <Redirect to="/login" />;
                        }
                        
                        if(isLogged && props.match.path === '/login') {
                            return <Redirect to="/" />;
                        }

                        return (
                            <>
                                {route.layout ? 
                                <route.layout {...props}>
                                    <route.component {...props} />
                                </route.layout>  
                                :
                                <route.component {...props} />
                                }
                            </>
                        );
                    })}
                    />
                );
                })}
            </div>
            </Router>
    );
}