
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Crud from '../../components/Crud';
import { InputMask, InputRadio, InputText } from '../../components/FormHelper/Input';

import Empresa from './Empresa';
import Empresario from './Empresario';
import Famoso from './Famoso';
import Igreja from './Igreja';
import Pastor from './Pastor';
import Politico from './Politico';

import CrudSmall from '../../components/CrudSmall';
import { propTypes } from 'react-bootstrap/esm/Image';
import Acoes from '../Acoes';

const FormWrapper = ({ Field, ErrorMessage, values, setFieldValue }) => {   

    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    
    function onChangeTipoParceiro(name, value) {
        setFieldValue(name, value);
        setFieldValue('cargo_parceiro', null);
    }
    
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="tipo_parceiro"
                        label={t('Tipo de Parceiro')}
                        name="tipo_parceiro"
                        onChange={onChangeTipoParceiro}
                        component={InputRadio}
                        list={[
                            { value: 'pessoa_fisica', label: t('Pessoa Física') },
                            { value: 'igrejas', label: t('Igreja') },
                            { value: 'midia', label: t('Mídia') },
                            { value: 'outras_empresas', label: t('Outras empresas') }
                        ]}
                        error={<ErrorMessage name="tipo_parceiro" />}
                    />
                </Grid>
            </Grid>
            
            {values.tipo_parceiro === 'pessoa_fisica' &&
                <>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Field 
                                required
                                column={3}
                                id="cargo_parceiro"
                                label={t('Cargo do Parceiro')}
                                name="cargo_parceiro"
                                component={InputRadio}
                                list={[
                                    { value: 'influencer_cantor', label: t('Influencer, cantor ou outra personalidade gospel') },
                                    // { value: 'empresario', label: t('Empresário') },
                                    { value: 'pastor', label: t('Pastor (não famoso)') },
                                    { value: 'politico', label: t('Político') }
                                ]}
                                error={<ErrorMessage name="cargo_parceiro" />}
                            />
                        </Grid>
                    </Grid>

                    {values.cargo_parceiro === 'influencer_cantor' && 
                        <Famoso values={values} setFieldValue={setFieldValue}/>
                    }

                    {values.cargo_parceiro === 'pastor' && 
                        <Pastor  values={values}  setFieldValue={setFieldValue} />
                    }

                    {values.cargo_parceiro === 'politico' && 
                        <Politico  values={values}  setFieldValue={setFieldValue}/>
                    }
                </>
            }
            
            {values.tipo_parceiro === 'midia' && 
                <>
                    <Empresa values={values} setFieldValue={setFieldValue} />
                </>
            }

            {values.tipo_parceiro === 'igrejas' && 
                <>
                    <Igreja values={values}  setFieldValue={setFieldValue}/>
                </>
            }

            {values.tipo_parceiro === 'outras_empresas' && 
                <>
                    <Empresario  values={values} setFieldValue={setFieldValue} />
                </>
            }

            {/* <Acoes titleAlt="Cadastrar Ações"/> */}
        </>
    );
}

export default function Parceiros() {
    const { t } = useTranslation();
    return (
        <Crud 
            title={t('Parceiros')}
            endPoint="parceiros"
            subtitle={t('Cadastros')}
            searchFieldName="search"
            emptyObject={{
                name: 'Teste',
                telefone: '',
                email: '',
                estado: '',
                cidade: '',

                tipo_parceiro: null,
                cargo_parceiro: '',

                instagram: '',
                facebook: '',
                youtube: '',

                instagram_seguidores: '',
                facebook_seguidores: '',
                youtube_seguidores: '',
                nicho: '',
                bancada_evangelia: '',
                nome_parceiro: '',
                
                telefone_parceiro: '',
                email_parceiro: '',
                estado_parceiro: '',
                cidade_parceiro: '',

                nome_igreja: '',
                numero_membros: '',
                numero_templos: '',
                e_sede: '',

                cargo: '',

                nome_empresa: '',
                rumo_atividade: '',
                tiragem_alcance: '',

                tipo: '',
                area_distribuicao: '',

                contato_parceiro: '',
                cargo_contato_parceiro: '',

                meios: [],
                igrejas: [],
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'nome_parceiro', label: t('Nome') },
                { name: 'tipo_parceiro', label: t('Tipo de Parceiro') },
                { name: 'cargo_parceiro', label: t('Cargo do Parceiro') },
            ]}
            fieldsHtml={({item}) => (
                <>
                    <td className="min-width">{item.id}</td>
                    <td>
                        {(['empresa', 'midia'].includes(item.tipo_parceiro)) ? 
                        item.nome_empresa : 
                        item.nome_parceiro}
                        {(['igrejas'].includes(item.tipo_parceiro)) ? 
                        item.nome_igreja : 
                        item.nome_parceiro}
                    </td>
                    <td>{t(item.tipo_parceiro)}</td>
                    <td>{t(item.cargo_parceiro)}</td>
                </>
            )}
            validation={(yup) => {
                return {
                    // name: yup.string().required(t('Campo obrigatório')),
                    // telefone: yup.string().required(t('Campo obrigatório')),
                    // estado: yup.string().required(t('Campo obrigatório')),
                    // cidade: yup.string().required(t('Campo obrigatório')),
                    
                    email: yup.string().nullable().email(t('E-mail inválido')),
                    tipo_parceiro: yup.string().nullable(),
                    email_parceiro: yup.string().email('E-mail inválido').nullable(),
                    cargo_parceiro: yup.string().nullable()
                    .when("tipo_parceiro", {
                        is: 'pessoa_fisica',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    
                    // CARGO PARCEIRO: INFLUENCER, EMPRESARIO E PASTOR
                    nome_parceiro: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'empresario',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'pastor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    telefone_parceiro: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'empresario',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'pastor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("tipo_parceiro", {
                        is: 'igreja',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    estado_parceiro: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'empresario',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'pastor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("tipo_parceiro", {
                        is: 'igreja',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    cidade_parceiro: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'empresario',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'pastor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("tipo_parceiro", {
                        is: 'igreja',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),

                    
                    nicho: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),

                    // CARGO PARCEIRO: INFLUENCER E POLITICO
                    instagram: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'politico',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    facebook: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'politico',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    youtube: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'politico',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    instagram_seguidores: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'politico',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    facebook_seguidores: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'politico',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    youtube_seguidores: yup.string().when("cargo_parceiro", {
                        is: 'influencer_cantor',
                        then: yup.string().required(t('Campo obrigatório'))
                    }).when("cargo_parceiro", {
                        is: 'politico',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    

                    // CARGO PARCEIRO: POLITICO
                    bancada_evangelia: yup.string().nullable().when("cargo_parceiro", {
                        is: 'politico',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                   

                    // TIPO PARCEIRO: IGREJA
                    nome_igreja: yup.string().nullable().when("tipo_parceiro", {
                        is: 'igreja',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    numero_membros: yup.string().nullable().when("tipo_parceiro", {
                        is: 'igreja',
                        then: yup.number().positive().integer().required(t('Campo obrigatório'))
                    }),
                    numero_templos: yup.string().nullable().when("tipo_parceiro", {
                        is: 'igreja',
                        then: yup.number().positive().integer().required(t('Campo obrigatório'))
                    }),
                    contato_parceiro: yup.string().nullable().when("tipo_parceiro", {
                        is: 'igreja',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    cargo_contato_parceiro: yup.string().nullable().when("tipo_parceiro", {
                        is: 'igreja',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),

                    nome_empresa: yup.string().nullable().when("tipo_parceiro", {
                        is: 'empresa',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    rumo_atividade: yup.string().nullable().when("tipo_parceiro", {
                        is: 'empresa',
                        then: yup.string().required(t('Campo obrigatório'))
                    }),
                    
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}



