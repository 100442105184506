import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputRadio, Select,  } from '../../components/FormHelper/Input';
import Crud from '../../components/Crud';
import Eventos from './Eventos';
import EmpresasParceiras from './EmpresasParceiras';
import MidiaDigital from './MidiaDigital';
import MidiaEletronica from './MidiaEletronica';
import MidiaImpressa from './MidiaImpressa';
import ReunioesFaceToFace from './ReunioesFaceToFace';
import VisitaIgrejas from './VisitaIgrejas';
import UploadImages from './UploadImages';
import Api from '../../core/api';
import SelectAutoComplete from '../../components/FormHelper/SelectAutoComplete';
import UploadImagesOriginal from './UploadImagesOriginal';

const FormWrapper = ({ Field, ErrorMessage, setFieldValue, values }) => {
    const { t } = useTranslation();
    const [projetos, setProjetos] = useState([]);

    function onChangeTipoParceiro(name, value) {
        setFieldValue(name, value);
        setFieldValue('cargo_parceiro', null);
    }

    async function getProjetos() {
        const response = await Api('get', 'projetos/options', '', {});
        setProjetos(response.data);
    }

    useEffect(() => {
        getProjetos();
    }, []);


    return (
        <>

            <Grid container spacing={2} >
                
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="projeto_id"
                        label={t('Projetos')}
                        name="projeto_id"
                        component={SelectAutoComplete}
                        endPoint="projetos/options"
                        placeholder={t("Selecione o Projeto")}
                        list={projetos}
                        error={<ErrorMessage name="projeto_id" />}
                    />
                </Grid>
            </Grid>   
            <hr className='auto' />
            <Grid container spacing={2}>
                
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="tipo_acao"
                        column={4}
                        label={t('Tipo de Ação')}
                        name="tipo_acao"
                        onChange={onChangeTipoParceiro}
                        component={InputRadio}
                        list={[
                            { value: 'visita_igrejas', label: t('Visita às Igrejas') },
                            { value: 'acoes_presenciais', label: t('Ações presenciais em empresas parceiras') },
                            { value: 'reunioes_face', label: t('Reuniões face-to-face') },
                            { value: 'eventos', label: t('Eventos') },
                            { value: 'midia_impressa', label: t('Mídia Impressa') },
                            { value: 'midia_eletronica', label: t('Mídia Eletrônica (Rádio ou TV)') },
                            { value: 'midia_digital', label: t('Mídia Digital') }
                        ]}
                        error={<ErrorMessage name="tipo_acao" />}
                    />
                </Grid>
            </Grid>
            <hr className='auto' />
            {values.tipo_acao === 'visita_igrejas' && <VisitaIgrejas values={values} setFieldValue={setFieldValue} />}
            {values.tipo_acao === 'acoes_presenciais' && <EmpresasParceiras values={values} setFieldValue={setFieldValue} />}
            {values.tipo_acao === 'reunioes_face' && <ReunioesFaceToFace values={values} setFieldValue={setFieldValue} />}
            {values.tipo_acao === 'eventos' && <Eventos values={values} setFieldValue={setFieldValue} />}
            {values.tipo_acao === 'midia_impressa' && <MidiaImpressa values={values} setFieldValue={setFieldValue} />}
            {values.tipo_acao === 'midia_eletronica' && <MidiaEletronica values={values} setFieldValue={setFieldValue} />}
            {values.tipo_acao === 'midia_digital' && <MidiaDigital values={values} setFieldValue={setFieldValue} />}
            <hr />
            <hr />
            
            {values.id && 
                <UploadImagesOriginal 
                    id={values.id} 
                    endPoint="acoes-images"
                />       
            }
        </>
    );
}

export default function Acoes() {

    const { t } = useTranslation();

    return (
        <Crud 
            title={t('Ações')}
            subtitle={t('Cadastros')}
            endPoint="acao"
            searchFieldName="search"
            titleBrnSaveAlt="Avançar para Carregar as Imagens"
            saveContinueForm={true}
            emptyObject={{
                tipo_acao: '',
                parceiro_id: '',
                distribuicao_material: '',
                exibicao_trailer: '',
                qr_code: '',
                panfletagem: '',
                exposicao_banner: '',
                exibicao_vt: '',
                testemunhal: '',
                data_inicio: '',
                data_fim: '',
                n_dias_divulgacao_efetiva: '',
                n_clientes_abordados: '',
                media_pessoas_impactadas: '',
                cafe_pastores: '',
                reuniao_lideres: '',
                eventos_cantores: '',
                outros_eventos: '',
                conselho_pastores: '',
                encontro_jovens: '',
                evento_infantil: '',
                data_acao: '',
                publico_medio_presente: '',
                havia_transmissao: '',
                publico_online_total: '',
                autoridade_crista_presentes: '',
                n_estimado_autoridades: '',
                autoridade_politicas_presentes: '',
                n_estimado_politicos: '',
                nicho_gospel: '',
                banner_superior: '',
                banner_lateral: '',
                banner_rodape: '',
                post_instagram: '',
                youtube_id: '',
                cliques_views: '',
                live_entrevista: '',
                testimonial_id: '',
                reels_id: '',
                stories_id: '',
                insercoes_id: '',
                pessoas_impactadas: '',
                data: '',
                pauta_id: '',
                entrevista_id: '',
                alcance_id: '',
                contatos_id: '',
                pastores_id: '',
                lider_id: '',
                membros_id: '',
                empresarios_id: '',
                catolica_id: '',
                taxa_id: '',
                programa_id: '',
                conteudo_id: '',
                _90segundos: '',
                _60segundos: '',
                _45segundos: '',
                _30segundos: '',
                _15segundos: '',
                houve_cobranca: '',
                valor_cobranca: '',
                anuncio: '',
                reportagem: '',
                total_anuncios: '',
                mes_circulacao: '',
                exibicao_material: '',
                exibicao_testemunhal: '',
                marcadores_paginas: '',
                data_reuniao: '',
                file: '',
                igreja_id: '',
                reuniao: '',
                numero_cultos: '',
                total_pessoas_impactadas: '',
                stage_mic: '',
                qr_code_no_telao: '',
                apresentacao_pastor: '',
                data_visita: '',
                network: false,
                expectativa: '',

            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'tipo_acao_f', label: t('Tipo de Ação') }
            ]}
            validation={(yup) => {
                return {
                    tipo_acao: yup.string().required(t('Campo obrigatorio')),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
