import React from 'react';
import CrudSmall from '../../../../components/CrudSmall';
import { ErrorMessage, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { InputCheckbox, InputMask, InputRadio, InputText, Select } from '../../../../components/FormHelper/Input';
import SelectEstados from '../../../../components/FormHelper/SelectEstados';
import SelectCidades from '../../../../components/FormHelper/SelectCidades';


export default function Cidades(props) {
    const { t } = useTranslation();

    return (
        <CrudSmall
            title="Circulações"
            titleBtnNew="Nova Cidade"
            endPoint="cidades-veiculos"
            id={props.values.id}
            param="veiculo_id"
            nameField="cidades"
            setFieldValue={props.setFieldValue}
            emptyObject={{
                estado: '',
                name: '',
            }}
            thead={() => (
                <>
                    <tr>
                        <th>Estado</th>
                        <th>Cidade</th>
                        <th>Ações</th>
                    </tr>
                </>
            )}
            Tbody={({ item }) => (
                <>
                    <th>{item.estado}</th>
                    <th>{item.name}</th>
                </>
            )}
            FormWrapper={({values }) => (
                <>
                    {/* {JSON.stringify(values, null, 2)} */}
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field 
                                required
                                id="estado"
                                label={t('Estados')}
                                name="estado"
                                cityName="cidade_parceiro"
                                component={SelectEstados}
                                list={[]}
                                error={<ErrorMessage name="estado" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field 
                                required
                                id="name"
                                label={t('Cidade')}
                                name="name"
                                component={SelectCidades}
                                estado={values.estado}
                                error={<ErrorMessage name="name" />}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        />
    )
}