import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shards-react';
import Api from '../../../core/api';
import Crud from '../../../components/Crud';
import { InputMask, InputRadio, InputText, Select, InputCheckbox } from '../../../components/FormHelper/Input';
import SelectAutoComplete from '../../../components/FormHelper/SelectAutoComplete';

export default function ReunioesFaceToFace(props) {
    const { t } = useTranslation();
    const [parceiros, setParceiros] = useState([]);

    async function getParceiros() {
        const response = await Api('get', 'parceiros/options', '', {});
        setParceiros(response.data);
    }

    useEffect(() => {
        getParceiros();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="parceiro_id"
                        label={t('Parceiro anfitrião/convidado:')}
                        name="parceiro_id"
                        component={SelectAutoComplete}
                        endPoint="parceiros/options"
                        list={parceiros}
                        error={<ErrorMessage name="parceiro_id" />}
                    />
                </Grid>
            </Grid>
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <h6 className='line-title'>Oportunidades:</h6>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exibicao_material"
                        label={t('Exibição de Material')}
                        name="exibicao_material"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exibicao_material" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exibicao_trailer"
                        label={t('Exibição de trailler')}
                        name="exibicao_trailer"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exibicao_trailer" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="panfletagem"
                        label={t('Panfletagem')}
                        name="panfletagem"
                        component={InputCheckbox}
                        error={<ErrorMessage name="panfletagem" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exibicao_vt"
                        label={t('Exibição de VT')}
                        name="exibicao_vt"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exibicao_vt" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exibicao_testemunhal"
                        label={t('Exibição de Testemunhal')}
                        name="exibicao_testemunhal"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exibicao_testemunhal" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="marcadores_paginas"
                        label={t('Marcadores de página ou outro material gráfico')}
                        name="marcadores_paginas"
                        component={InputCheckbox}
                        error={<ErrorMessage name="marcadores_paginas" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="network"
                        label={t('Network')}
                        name="network"
                        component={InputCheckbox}
                        error={<ErrorMessage name="network" />}
                    />
                </Grid>
                               
            </Grid>
            {props.values.network && 
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Field 
                            required
                            columns={4}
                            id="expectativa"
                            label={t('Expectativa')}
                            name="expectativa"
                            component={InputRadio}
                            list={[
                                { label: 'Abertura para eventos', value: 'Abertura para eventos' },
                                { label: 'Patrocínio', value: 'Patrocínio' },
                                { label: 'Ações em Igrejas', value: 'Ações em Igrejas' },
                                { label: 'Ações políticas', value: 'Ações políticas' },
                                { label: 'Marketing', value: 'Marketing' },
                                { label: 'Marketing de influência', value: 'Marketing de influência' }
                            ]}
                            error={<ErrorMessage name="expectativa" />}
                        />
                    </Grid>
                </Grid>
            }
            <hr className='auto' />
            
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="data_reuniao"
                        label={t('Data da Reunião')}
                        name="data_reuniao"
                        mask="##/##/####"
                        component={InputMask}
                        error={<ErrorMessage name="data_reuniao" />}
                    />
                </Grid>
            </Grid>

           
            
        </>
    );
}