import React, { memo, useEffect, useState } from 'react';
import { FormSelect } from 'shards-react';
import api from '../../../core/api';

function SelectCidades(props) {
    const [cidades, setCidades] = useState([]);
    async function getCidades() {
        if(props.estado) {
            const response = await api('get', `cidades/${props.estado}`, '', {});      
            setCidades(response);
        }
    }
    useEffect(() => {
        getCidades();
    }, [props.estado]);

    return (
        <>
            <label id={props.id}>{props.label} {props.required ? <span className="required-icon">*</span> : ''}</label>
            <FormSelect
                margin='normal'
                size="small"
                labelId={props.id}
                id={props.id}
                label={props.label}
                {...props.field}
                onChange={e => {
                    if(props.handleChange) {
                        props.handleChange(e.target.value);
                    } else {
                        props.form.setFieldValue(props.field.name, e.target.value)
                    }
                }}
            >
                {cidades.map(item => (
                    <option value={item.value}>{item.label}</option>
                ))}
            </FormSelect>
            <div><span className="error-validation">{props.error}</span></div>
        </>
    );
}

export default memo(SelectCidades);