
import { Formik, useFormik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { BsLayoutSidebar } from 'react-icons/bs';
import { Button, ButtonGroup, Modal, ModalBody, ModalHeader } from 'shards-react';
import Api from '../../core/api';

function CrudSmall(props) {

    const [emptyObject, setEmptyObject] = useState({});
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const [list, setList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditIndex, setIsEditIndex] = useState(null);

    async function getData(){
        if(props.id) {
            const response = await Api('get', `${props.endPoint}`, '', {
                params: {
                    [props.param]: props.id
                }
            });
            setList(response.data);
        }
    }

    function handleSubmit(values){
        setOpen(false);
        if(isEdit) {
            if(props.id) {
                Api('put', `${props.endPoint}/${values.id}`, '', {
                    ...values,
                    [props.param]: props.id
                });
                getData();
            } else {
                let lis = list;
                lis[isEditIndex] = values;
                setList([...lis]);
                props.setFieldValue(props.nameField, lis);
            }
        } else {
            if(props.id) {
                Api('post', `${props.endPoint}`, '', {
                    ...values,
                    [props.param]: props.id
                });
                getData();
            } else {
                let lis = list;
                lis.push(values);
                console.log(lis);
                setList([...lis]);
                props.setFieldValue(props.nameField, lis);
            }
        }
        setIsEditIndex(null);
        setData({});
        setOpen(false);
    }

    async function handleEdit(item, index){
        setIsEdit(true);
        setIsEditIndex(index);
        setEmptyObject(list[index]);
        setOpen(true);
    }

    async function deleteRow(item, index){
        if(props.id) {
            await Api('delete', `${props.endPoint}/${item.id}`, {});
            getData();
        } else {
            let lis = list.filter((item, idx) => idx !== index);
            setList([...lis]);
        }
    }
   
    useEffect(() => {
        getData();
    }, [props.id]);

    useEffect(() => {
        if(!isEdit) {
            setEmptyObject(props.emptyObject);
        }
    }, [open]);

    return (
        <>
            <Modal open={open} toggle={() => setOpen(!open)}>
                <ModalHeader>{props.titleBtnNew}</ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{...emptyObject}}
                        onSubmit={handleSubmit}
                    >
                        {(objects) => {
                            return (
                                <form>
                                    {props.FormWrapper && <props.FormWrapper {...props} {...objects} submit={handleSubmit}  /> }
                                    <Button onClick={() => objects.handleSubmit()}>Salvar</Button>
                                </form>
                            );
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
            <Button onClick={() => {
                setEmptyObject({...props.emptyObject});
                setIsEditIndex(null);
                setIsEdit(false);
                setData({});
                setOpen(!open);

            }}>{props.titleBtnNew}</Button>
            
            <table className="table mb-0" style={{ marginTop: '10px' }}>
                <thead className="bg-light">
                    {props.thead && <props.thead {...props} />}
                </thead>
                <tbody>
                    {list.map((item, index) => (
                        <tr>
                            {props.Tbody && <props.Tbody {...props} item={item} index={index} />}
                            <td>
                                <Button theme="primary" size="sm" onClick={() => handleEdit(item, index)}>Editar</Button>
                                &nbsp;
                                <Button theme="danger" size="sm" onClick={() => deleteRow(item, index)}>Excluir</Button>
                            </td>
                        </tr>
                    ))}
                    
                </tbody>
            </table>
        </>
    );
}

CrudSmall.defaultProps = {
    FormWrapper: null,
    thead: null,
    Tbody: null,
    validation: null,
    setFieldValue: null
};

export default memo(CrudSmall);
