import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button } from 'shards-react';
export default function FormButtons(props) {
    const { t } = useTranslation();
    return (
        <FormGroup style={{ marginTop: '10px', float: 'right' }}>
            {props.enableBtnSave && 
                <Button size="sm" theme="success" onClick={props.handleSave}>{t(props.titleBrnSaveAlt && props.view === 'new' ? props.titleBrnSaveAlt : props.titleBtnSave)}</Button>
            }
            &nbsp;
            {props.enableBtnCancel && 
                <Button size="sm" theme="warning" onClick={props.handleCancel}>{t(props.titleBtnCancel)}</Button>
            }
        </FormGroup>
    );
}

FormButtons.defaultProps = {
    enableBtnSave: true,
    enableBtnCancel: true,
    titleBtnSave: 'Salvar',
    titleBrnSaveAlt: null,
    titleBtnCancel: 'Cancelar',
};