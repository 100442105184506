import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shards-react';
import Api from '../../../core/api';
import Crud from '../../../components/Crud';
import { InputMask, InputRadio, InputText, Select, InputCheckbox } from '../../../components/FormHelper/Input';
import SelectAutoComplete from '../../../components/FormHelper/SelectAutoComplete';

export default function EmpresasParceiras(props) {
    const { t } = useTranslation();
    const [parceiros, setParceiros] = useState([]);

    async function getParceiros() {
        const response = await Api('get', 'parceiros/options', '', {});
        setParceiros(response.data);
    }

    useEffect(() => {
        getParceiros();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="parceiro_id"
                        label={t('Parceiros')}
                        name="parceiro_id"
                        component={SelectAutoComplete}
                        endPoint="parceiros/options"
                        placeholder="Selecione o Parceiro"
                        list={parceiros}
                        error={<ErrorMessage name="parceiro_id" />}
                    />
                </Grid>
            </Grid>   
            
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <h6 className='line-title'>Oportunidades:</h6>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="distribuicao_material"
                        label={t('Distribuição de Material')}
                        name="distribuicao_material"
                        component={InputCheckbox}
                        error={<ErrorMessage name="distribuicao_material" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exibicao_trailer"
                        label={t('Exibição de trailler')}
                        name="exibicao_trailer"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exibicao_trailer" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="qr_code"
                        label={t('QR Code')}
                        name="qr_code"
                        component={InputCheckbox}
                        error={<ErrorMessage name="qr_code" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="panfletagem"
                        label={t('Panfletagem')}
                        name="panfletagem"
                        component={InputCheckbox}
                        error={<ErrorMessage name="panfletagem" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exposicao_banner"
                        label={t('Exposição de Banner')}
                        name="exposicao_banner"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exposicao_banner" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exibicao_vt"
                        label={t('Exibição de VT')}
                        name="exibicao_vt"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exibicao_vt" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="testemunhal"
                        label={t('Testemunhal')}
                        name="testemunhal"
                        component={InputCheckbox}
                        error={<ErrorMessage name="testemunhal" />}
                    />
                </Grid>
                
            </Grid>
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <h6 className='line-title'>Período de Ação:</h6>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="data_inicio"
                        label={t('Data de inicio')}
                        name="data_inicio"
                        mask="##/##/####"
                        component={InputMask}
                        error={<ErrorMessage name="data_inicio" />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="data_fim"
                        label={t('Data Fim')}
                        name="data_fim"
                        pattern="[0-9]*"
                        mask="##/##/####"
                        component={InputMask}
                        error={<ErrorMessage name="data_fim" />}
                    />
                </Grid>
            </Grid>
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="n_dias_divulgacao_efetiva"
                        label={t('Nº de dias  de duvulgação efetiva:')}
                        name="n_dias_divulgacao_efetiva"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="n_dias_divulgacao_efetiva" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="n_clientes_abordados"
                        label={t('Nº de clientes abordados:')}
                        name="n_clientes_abordados"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="n_clientes_abordados" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="media_pessoas_impactadas"
                        label={t('Médias de pessoas impactadas:')}
                        name="media_pessoas_impactadas"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="media_pessoas_impactadas" />}
                    />
                </Grid>
            </Grid>

            
        </>
    );
}