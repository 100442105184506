import React, { memo, useEffect, useState } from "react";
import { FormSelect } from 'shards-react';
import api from "../../../core/api";

function SelectEstados(props) {

    const [estados, setEstados] = useState([]);
    async function getEstados() {
        const response = await api('get', `estados`, '', {});      
        console.log(response);
        setEstados(response);
    }
    useEffect(() => {
        getEstados();
    }, []);

    return (
        <>
            <label id={props.id}>{props.label} {props.required ? <span className="required-icon">*</span> : ''}</label>
            <FormSelect
                margin='normal'
                size="small"
                labelId={props.id}
                id={props.id}
                label={props.label}
                {...props.field}
                onChange={e => {
                    if(props.handleChange) {
                        props.handleChange(e.target.value);
                        props.form.setFieldValue(props.cityName, '');
                    } else {
                        props.form.setFieldValue(props.field.name, e.target.value)
                        props.form.setFieldValue(props.cityName, '');
                    }
                }}
            >
                {estados.map(item => (
                    <option value={item.value}>{item.label}</option>
                ))}
            </FormSelect>
            <div><span className="error-validation">{props.error}</span></div>
        </>
    );
}

SelectEstados.defaultProps = {
    cityName: '',
};

export default memo(SelectEstados);