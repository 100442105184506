import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
// import { Form, InputGroup, Button as Btn,  } from 'react-bootstrap';
import IntlCurrencyInput from "react-intl-currency-input"
import { Col, FormCheckbox, FormGroup, FormInput, FormRadio, FormSelect, Row, Button as Btn } from 'shards-react';
import Api from '../../../core/api';
export function InputText(props) {
    return (
        <>
            <label>{props.label} {props.required ? <span className="required-icon">*</span> : ''}</label>
            <FormInput
                label={props.label}
                {...props}
                {...props.field}
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
                disabled={props.disabled ? true : false}
                onChange={e => {
                    if(props.pattern) {
                        if(e.target.validity.valid) {
                            props.form.setFieldValue(props.field.name, e.target.value);
                        }
                        if(e.target.value === '') {
                            props.form.setFieldValue(props.field.name, e.target.value);
                        }
                    } else {
                        props.form.setFieldValue(props.field.name, e.target.value);
                    }
                }}
            />
            <div><span className="error-validation">{props.error}</span></div>
        </>
    );
}

InputText.defaultProps = {
    error: null,
    errors: null,
    pattern: null,  
}

const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
};


const currencyConfigNumber = {
    locale: "en",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
};
export function InputTextFormat(props) {

    return (
        <FormGroup>
            {props.label && 
                <label className={props.required && `required`} htmlFor={props.id}>{props.label}</label> 
            }
            <IntlCurrencyInput 
                currency="BRL" 
                config={currencyConfig} 
                onChange={e => console.log(e)} 
                {...props} 
                {...props.field} 
                value={parseFloat(props.field.value)} 
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`}
            />
            <div><span className="error-validation">{props.error}</span></div>
        </FormGroup>
    );
}

InputTextFormat.defaultProps = {
    handleChange: null,
}

export function InputMask(props) {
    return (
        <FormGroup>
            {props.label && 
                <label className={props.required && `required`} htmlFor={props.id}>{props.label} {props.required ? <span className="required-icon">*</span> : ''}</label> 
            }
            <NumberFormat 
                {...props} 
                {...props.field} 
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`}
                style={{ borderRadius: 5, borderColor: '#CCC' }}
                format={props.mask}
                mask=""
            />
            <div><span className="error-validation">{props.error}</span></div>
        </FormGroup>
    );
    
}
InputMask.defaultProps = {
    mask: '',
};

export function InputRadioCheckbox(props) {
    return (
        <FormGroup>
            <label control={
                    <FormCheckbox
                        {...props.field} 
                        defaultChecked={props.field.value}
                        onChange={e => {
                            props.form.setFieldValue(props.field.name, e.target.checked);
                        }}
                    />
                } 
                label={props.label} 
            />
            <div><span className="error-validation">{props.error}</span></div>
        </FormGroup>
    );
}

export function InputRadio(props) {
    return (
        <>
            <label htmlFor={props.id} style={{ marginTop: 10 }}>{props.label} {props.required ? <span className="required-icon">*</span> : ''}</label>
            <Row container>
                {props.list.map((item, index) => (
                    <Col key={index} item md={props.column}>
                        <FormRadio
                            id={props.id}
                            name={props.name}
                            checked={props.field.value === item.value}
                            onClick={() => {
                                if(props.onChange) {
                                    props.onChange(props.id, item.value);
                                } else {
                                    props.form.setFieldValue(props.id, item.value);
                                }
                                if(props.callBack) {
                                    props.callBack(item.value);
                                }
                            }}
                            onChange={() => {        
                                if(props.onChange) {
                                    props.onChange(props.id, item.value);
                                } else {
                                    props.form.setFieldValue(props.id, item.value);
                                }
                            }}  
                            fullWidth
                        >
                            {item.label}
                        </FormRadio>
                    </Col>
                ))}
            </Row>
            <div><span className="error-validation">{props.error}</span></div>
        </>
        // <FormGroup>
        //     <FormControlLabel control={
        //             <Checkbox 
        //                 {...props.field} 
        //                 defaultChecked={props.field.value}
        //                 onChange={e => {
        //                     props.form.setFieldValue(props.field.name, e.target.checked);
        //                 }}
        //             />
        //         } 
        //         label={props.label} 
        //     />
        //     <div><span className="error-validation">{props.error}</span></div>
        // </FormGroup>
    );
}

InputRadio.defaultProps = {
    column: 2,
    onChange: null
};

export function InputCheckbox(props) {
    return (
        <>
            <FormCheckbox
                name={props.name}
                id={props.id}
                checked={props.field.value}
                onChange={(e) => {        
                    if(props.onChange !== undefined) {
                        props.onChange(props.id, !props.field.value);
                    } else {    
                        props.form.setFieldValue(props.id, !props.field.value);
                    }
                }}  
                onClick={(e) => {        
                    if(props.onChange !== undefined) {
                        props.onChange(props.id, !props.field.value);
                    } else {    
                        props.form.setFieldValue(props.id, !props.field.value);
                    }
                }}  
                fullWidth
            >
                <label htmlFor={props.id} style={{ marginTop: 0 }}>{props.label} {props.required ? <span className="required-icon">*</span> : ''}</label>
            </FormCheckbox>
            <div><span className="error-validation">{props.error}</span></div>
        </>
        // <FormGroup>
        //     <FormControlLabel control={
        //             <Checkbox 
        //                 {...props.field} 
        //                 defaultChecked={props.field.value}
        //                 onChange={e => {
        //                     props.form.setFieldValue(props.field.name, e.target.checked);
        //                 }}
        //             />
        //         } 
        //         label={props.label} 
        //     />
        //     <div><span className="error-validation">{props.error}</span></div>
        // </FormGroup>
    );
}

InputCheckbox.defaultProps = {
    column: 2
};

export function Button(props) {
    return (
        <Btn {...props}>{props.name}</Btn>
    );
}

export function Select(props) {
    return (
        <>
            <label id={props.id} className={props.required && `required`}>{props.label}</label>
            
            <FormSelect
                margin='normal'
                size="small"
                labelId={props.id}
                id={props.id}
                label={props.label}
                {...props.field}
                onChange={e => {
                    if(props.handleChange) {
                        props.handleChange(e.target.value);
                    } else {
                        props.form.setFieldValue(props.field.name, e.target.value)
                    }
                }}
            >
                <option value="">{props.placeholder}</option>
                {props.list.map(item => (
                    <option value={item.value}>{item.label}</option>
                ))}
            </FormSelect>
            <div><span className="error-validation">{props.error}</span></div>
            {/* <FormGroup>
                <Form.Label className={props.required && `required`}  htmlFor={props.id}>{props.label}</Form.Label>
                <Form.Control 
                    as="select" 
                    custom  
                    {...props} 
                    {...props.field} 
                    onChange={e => {
                        if(props.handleChange) {
                            props.handleChange(e.target.value);
                        } else {
                            props.form.setFieldValue(props.field.name, e.target.value)
                        }
                    }}
                >
                    <option value="">{props.placeholder}</option>
                    {props.list.map(item => (
                        <option value={item.value}>{item.label}</option>
                    ))}
                </Form.Control>
            </FormGroup> */}
            
        </>
    );
}

Select.defaultProps = {
    handleChange: null,
    placeholder: 'Selecione'
}

export function FileUpload(props) {
    return (
        <FormGroup>
            {props.label && 
                <label className={props.required && `required`} htmlFor={props.id}>{props.label}</label> 
            }
            <FormInput 
                {...props} 
                {...props.field}  
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`} 
                onChange={e => props.handleFile(e)}
            />
            <div><span className="error-validation">{props.error}</span></div>
        </FormGroup>
    );
}

export function InputTextarea(props) {
    return (
        <FormGroup>
            {props.label && 
                <label className={props.required && `required`} htmlFor={props.id}>{props.label}</label> 
            }
            <FormInput 
                as="textarea" 
                {...props} 
                {...props.field}  
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`} 
            />
            <div><span className="error-validation">{props.error}</span></div>
        </FormGroup>
    );
}

export function InputFile(props) {

    const [fileName, setFileName] = useState('');

    function handleFile(e) {
        if(e.target.files.length) {
            const file = e.currentTarget.files[0];
            setFileName(file.name);
            if(props.loadFile) {
                props.loadFile(file);
            }
            
        } else {
            setFileName('');
        }
    }

    return (
        <FormGroup>
            {props.label && 
                <label className={props.required && `required`} htmlFor={props.id}>{props.label}</label> 
            }
            <FormInput
                {...props} 
                {...props.field}  
                onChange={handleFile}
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`} />
            <div><span className="error-validation">{props.error}</span></div>
            <div><span>{fileName}</span></div>
        </FormGroup>
    );
}


export function Cidades(props) {
    const [cidades, setCidades] = useState([]);
    async function getCidades() {
        const response = await Api('get', `cidades`, '', {});      
        setCidades(response);
    }

    useEffect(() => {
        getCidades();
    }, []);

    return (
        <>
            <label id={props.id}>{props.label} {props.required ? <span className="required-icon">*</span> : ''}</label>
            <FormSelect
                margin='normal'
                size="small"
                labelId={props.id}
                id={props.id}
                label={props.label}
                {...props.field}
                onChange={e => {
                    if(props.handleChange) {
                        props.handleChange(e.target.value);
                    } else {
                        props.form.setFieldValue(props.field.name, e.target.value)
                    }
                }}
            >
                {cidades.map(item => (
                    <option value={item.value}>{item.label}</option>
                ))}
            </FormSelect>
            <div><span className="error-validation">{props.error}</span></div>
        </>
    );
}


InputFile.defaultProps = {
    error: null,
    errors: null,
    loadFile: null,
}


export function InputTextFormatNumber(props) {

    return (
        <FormGroup>
            {props.label && 
                <label className={props.required && `required`} htmlFor={props.id}>{props.label}</label> 
            }
            <IntlCurrencyInput 
                currency="" 
                config={currencyConfigNumber} 
                onChange={e => console.log(e)} 
                {...props}  
                {...props.field} 
                value={parseFloat(props.field.value)} 
                className={`form-control ${props.form.errors[props.field.name] ? 'error-input': ''}`}
            />
                <div><span className="error-validation">{props.error}</span></div>
        </FormGroup>
    );
}

InputTextFormatNumber.defaultProps = {
    handleChange: null,
}