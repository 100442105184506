import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
 
import "./index.css";
import { AuthProvider } from "./context/auth";
import MainRouter from "./main-router";


export default () => {
  
  return (
    <AuthProvider>
      <MainRouter />
    </AuthProvider>
  );
};
