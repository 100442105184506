import React, { useContext } from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { useTranslation } from "react-i18next";
import AuthContext from '../../../context/auth';

function SidebarNavItems(props){
  
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  
  const ADMIN = "1";
  const ASSESSOR = "2";
  const COLABORADOR = "3";
  const CLIENTE = "4";
  
  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">

        <SidebarNavItem key={1} item={{
            title: t('Painel'),
            to: "/home",
            htmlBefore: '<i class="material-icons">edit</i>',
            htmlAfter: ""
        }} />

        {user.role !== CLIENTE && 
          <>
            {user.role !== ASSESSOR && 
              <SidebarNavItem key={2} item={{
                title: t('Projetos'),
                htmlBefore: '<i class="material-icons">vertical_split</i>',
                to: "/projetos",
              }} />
            }
            <SidebarNavItem key={3} item={{
              title: t('Parceiros'),
              htmlBefore: '<i class="material-icons">note_add</i>',
              to: "/parceiros",
            }} />
            <SidebarNavItem key={2} item={{
              title: t('Ações'),
              htmlBefore: '<i class="material-icons">vertical_split</i>',
              to: "/eventos",
            }} />
            {user.role !== ASSESSOR && 
              <SidebarNavItem key={2} item={{
                title: t('Briefings'),
                htmlBefore: '<i class="material-icons">vertical_split</i>',
                to: "/briefings",
              }} />
            }
            
          </>
        }

        {user.role !== ASSESSOR && user.role !== COLABORADOR && 
          <SidebarNavItem key={4} item={{
            title: t('Usuários'),
            htmlBefore: '<i class="material-icons">view_module</i>',
            to: "/components-overview",
          }} />
        }
      </Nav>
    </div>
  );
}


export default SidebarNavItems;
