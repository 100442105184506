import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { InputCheckbox, InputMask, InputRadio, InputText, Select } from '../../../../components/FormHelper/Input';
import Cidades from '../Cidades';

export default function RevistaImpressa(props) {
    const { t } = useTranslation();

    return (
        <>
            {/* <Grid container>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        column={3}
                        id="nicho"
                        label={t('Nicho')}
                        name="nicho"
                        component={InputRadio}
                        list={[
                            { value: 'gospel', label: t('Gospel') },
                            { value: 'nao_gospel', label: t('Não Gospel') }
                        ]}
                        error={<ErrorMessage name="nicho" />}
                    />
                </Grid>
            </Grid> */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="tiragem"
                        label={t('Tiragem')}
                        name="tiragem"
                        component={InputText}
                        error={<ErrorMessage name="tiragem" />}
                    />
                </Grid>
            </Grid>
            {/* <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="circulacao"
                        label={t('Circulação')}
                        name="circulacao"
                        component={InputText}
                        error={<ErrorMessage name="circulacao" />}
                    />
                </Grid>
            </Grid> */}
            
            <br />
            <h6 className='line-title'>{t('Circulação')}
                <span className='error-validation'> *</span>
            </h6>
            <Cidades values={props.values} setFieldValue={props.setFieldValue}  />
            <br />
            <hr />
            <h6>Perfil da audiência</h6>
            <hr />
            <h6>Classe Social:</h6>
            <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                    <Field 
                        
                        id="classe_a"
                        label={t('A')}
                        name="classe_a"
                        value={false}
                        component={InputCheckbox}
                        error={<ErrorMessage name="classe_a" />}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <Field 
                        
                        id="classe_b"
                        label={t('B')}
                        name="classe_b"
                        component={InputCheckbox}
                        error={<ErrorMessage name="classe_b" />}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <Field 
                        
                        id="classe_c"
                        label={t('C')}
                        name="classe_c"
                        component={InputCheckbox}
                        error={<ErrorMessage name="classe_c" />}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <Field 
                        
                        id="classe_d"
                        label={t('D')}
                        name="classe_d"
                        component={InputCheckbox}
                        error={<ErrorMessage name="classe_d" />}
                    />
                </Grid>
                <Grid item xs={6} md={2}>
                    <Field 
                        
                        id="classe_e"
                        label={t('E')}
                        name="classe_e"
                        component={InputCheckbox}
                        error={<ErrorMessage name="classe_e" />}
                    />
                </Grid>
            </Grid>

            <h6>Sexo:</h6>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Field
                        required
                        id="feminino"
                        label={t('Feminino (%)')}
                        name="feminino"
                        component={InputText}
                        error={<ErrorMessage name="feminino" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field
                        required
                        id="masculino"
                        label={t('Masculino (%)')}
                        name="masculino"
                        component={InputText}
                        error={<ErrorMessage name="masculino" />}
                    />
                </Grid>
            </Grid>
                
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        column={3}
                        id="idade_predominante"
                        label={t('Idade predominante')}
                        name="idade_predominante"
                        component={InputRadio}
                        list={[
                            { value: '19_ou_menos', label: t('19 ou menos') },
                            { value: '20_29', label: t('20 a 29') },
                            { value: '30_39', label: t('30 a 39') },
                            { value: '40_49', label: t('40 a 49') },
                            { value: '50_59', label: t('50 a 59') },
                            { value: '60_mais', label: t('60 ou Mais') },
                        ]}
                        error={<ErrorMessage name="idade_predominante" />}
                    />
                </Grid>
            </Grid>
        </>
    );

}