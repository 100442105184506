import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputMask, InputRadio, InputText, Select } from '../../../components/FormHelper/Input';
import SelectCidades from '../../../components/FormHelper/SelectCidades';
import SelectEstados from '../../../components/FormHelper/SelectEstados';
import api from '../../../core/api';

export default function Empresario(props) {
    const { t } = useTranslation();
    const [list, setList] = useState([]);

    async function getList() {
        const response = await api('get', 'meios-comunicacoes-outros', '', {});
        setList(response.data);
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <hr />
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="nome_parceiro"
                        label={t('Nome da Empresa')}
                        name="nome_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="nome_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        required
                        id="telefone_parceiro"
                        label={t('Telefone')}
                        name="telefone_parceiro"
                        component={InputMask}
                        mask="(##) ####.####"
                        error={<ErrorMessage name="telefone_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="email_parceiro"
                        label={t('E-mail')}
                        name="email_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="email_parceiro" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="estado_parceiro"
                        label={t('Estados')}
                        name="estado_parceiro"
                        cityName="cidade_parceiro"
                        component={SelectEstados}
                        list={[]}
                        error={<ErrorMessage name="estado_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="cidade_parceiro"
                        label={t('Cidade')}
                        name="cidade_parceiro"
                        component={SelectCidades}
                        estado={props.values.estado_parceiro}
                        error={<ErrorMessage name="cidade_parceiro" />}
                    />
                </Grid>
            </Grid>
            <Grid container>
                {/* <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="nome_empresa"
                        label={t('Nome da Empresa')}
                        name="nome_empresa"
                        component={InputText}
                        error={<ErrorMessage name="nome_empresa" />}
                    />
                </Grid> */}
                 <Grid item xs={12} md={12}>
                    <Field
                        required
                        columns={3}
                        id="nicho"
                        label={t('Nicho?')}
                        name="nicho"
                        component={InputRadio}
                        list={[
                            { value: 'evangelico', label: 'Evangélico' },
                            { value: 'catolico', label: 'Católico' },
                            { value: 'secular', label: 'Secular' },
                            { value: 'outros', label: 'Outros' }
                        ]}
                        error={<ErrorMessage name="nicho" />}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="rumo_atividade"
                        label={t('Ramo da Atividade')}
                        name="rumo_atividade"
                        component={Select}
                        list={list}
                        error={<ErrorMessage name="rumo_atividade" />}
                    />
                </Grid>
            </Grid>
            
        </>
    );
}