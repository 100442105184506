import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shards-react';
import Api from '../../../core/api';
import Crud from '../../../components/Crud';
import { InputMask, InputRadio, InputText, Select, InputCheckbox, InputTextFormat } 
from '../../../components/FormHelper/Input';
import SelectAutoComplete from '../../../components/FormHelper/SelectAutoComplete';

export default function MidiaEletronica(props) {

    const { t } = useTranslation();
    const [parceiros, setParceiros] = useState([]);

    async function getParceiros() {
        const response = await Api('get', 'parceiros/options', '', {});
        setParceiros(response.data);
    }

    useEffect(() => {
        getParceiros();
    }, []);
    

    return (
        <>
            <Grid container spacing={2}>
                    
                    <Grid item xs={12} md={12}>
                        <Field
                            required
                            id="parceiro_id"
                            label={t('Selecione o Parceiro')}
                            name="parceiro_id"
                            component={SelectAutoComplete}
                            endPoint="parceiros/options"
                            list={parceiros}
                            error={<ErrorMessage name="parceiro_id" />}
                        />
                    </Grid>
            </Grid>
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Field 
                        required
                        id="nome_programa"
                        label={t('Nome do programa:')}
                        name="nome_programa"
                        component={InputText}
                        error={<ErrorMessage name="nome_programa" />}
                    />
                </Grid>
            </Grid>
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="nicho_programa"
                        label={t('Nicho do programa:')}
                        name="nicho_programa"
                        component={InputRadio}
                        list={[
                            { value: 'evangelico', label: t('Evangélico') },
                            { value: 'catolico', label: t('Católico') },
                            { value: 'secular', label: t('Secular') },
                            { value: 'outros', label: t('Outros') }
                        ]}
                        error={<ErrorMessage name="nicho_programa" />}
                    />
                </Grid>
            </Grid> 
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="tipo_anuncio"
                        label={t('Tipo de conteúdo:')}
                        name="tipo_anuncio"
                        component={InputRadio}
                        list={[
                            { value: 'anuncio', label: t('Anúncio') },
                            { value: 'reportagem', label: t('Reportagem') },
                            { value: 'merchandising', label: t('Merchandising') }
                        ]}
                        error={<ErrorMessage name="tipo_anuncio" />}
                    />
                </Grid>
            </Grid> 
            {props.values.tipo_anuncio === 'anuncio' &&
                <>
                    <hr className='auto' />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <h6>
                                Tamanho do anúncio:
                            </h6>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="90_segundos"
                                label={t('90 segundos')}
                                name="90_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="90_segundos" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="60_segundos"
                                label={t('60 segundos')}
                                name="60_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="60_segundos" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="45_segundos"
                                label={t('45 segundos')}
                                name="45_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="45_segundos" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="30_segundos"
                                label={t('30 segundos')}
                                name="30_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="30_segundos" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="15_segundos"
                                label={t('15 segundos')}
                                name="15_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="15_segundos" />}
                            />
                        </Grid>
                    </Grid>
                    <hr className='auto' />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Field 
                                required
                                id="total_insercoes"
                                label={t('Total de inserções:')}
                                name="total_insercoes"
                                pattern="[0-9]*"
                                component={InputText}
                                error={<ErrorMessage name="total_insercoes" />}
                            />
                        </Grid>
                        {/* alteração n° 28
                        <Grid item xs={12} md={4}>
                            <Field 
                                required
                                id="mes"
                                label={t('Mês de divulgação:')}
                                name="mes"
                                mask="##/##/####"
                                component={InputMask}
                                error={<ErrorMessage name="mes" />}
                            />
                        </Grid>
                    fim alteração n° 28*/}
                    </Grid>
                </>
            }
            
            {props.values.tipo_anuncio === 'reportagem' &&
                <>
                    <hr className='auto' />
                    <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Field
                                    required
                                    id="pauta_id"
                                    label={t('Selecionar pauta:')}
                                    name="pauta_id"
                                    component={Select}
                                    list={parceiros}
                                    error={<ErrorMessage name="pauta_id" />}
                                />
                            </Grid>
                    </Grid>
                    <hr className='auto' />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Field
                                required
                                id="entrevista_id"
                                label={t('Houve entrevista?')}
                                name="entrevista_id"
                                component={InputRadio}
                                list={[
                                    { value: 'sim', label: t('Sim') },
                                    { value: 'nao', label: t('Não') }
                                ]}
                                error={<ErrorMessage name="entrevista_id" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Field 
                                required
                                id="insercoes_id"
                                label={t('Total de inserções:')}
                                name="insercoes_id"
                                pattern="[0-9]*"
                                component={InputText}
                                error={<ErrorMessage name="insercoes_id" />}
                            />
                        </Grid>
                    </Grid>
                </>
            }

            {props.values.tipo_anuncio === 'anuncio' &&
                <>
                    <hr className='auto' />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <h6>
                                Tamanho do anúncio:
                            </h6>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="90_segundos"
                                label={t('90 segundos')}
                                name="90_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="90_segundos" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="60_segundos"
                                label={t('60 segundos')}
                                name="60_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="60_segundos" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="45_segundos"
                                label={t('45 segundos')}
                                name="45_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="45_segundos" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="30_segundos"
                                label={t('30 segundos')}
                                name="30_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="30_segundos" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="15_segundos"
                                label={t('15 segundos')}
                                name="15_segundos"
                                component={InputCheckbox}
                                error={<ErrorMessage name="15_segundos" />}
                            />
                        </Grid>
                    </Grid>
                    <hr className='auto' />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Field 
                                required
                                id="total_insercoes"
                                label={t('Total de inserções:')}
                                name="total_insercoes"
                                pattern="[0-9]*"
                                component={InputText}
                                error={<ErrorMessage name="total_insercoes" />}
                            />
                        </Grid>
                        {/* alteração n° 28
                        <Grid item xs={12} md={4}>
                            <Field 
                                required
                                id="mes"
                                label={t('Mês de divulgação:')}
                                name="mes"
                                mask="##/##/####"
                                component={InputMask}
                                error={<ErrorMessage name="mes" />}
                            />
                        </Grid>
                    fim alteração n° 28*/}
                    </Grid>
                </>
            }

            {props.values.tipo_anuncio === 'merchandising' &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Field 
                                required
                                id="total_insercao"
                                label={t('Total de Inserções')}
                                name="total_insercao"
                                pattern="[0-9]*"
                                component={InputText}
                                error={<ErrorMessage name="total_insercao" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <h6 className='line-title'>{t('Período de Inserção:')}</h6>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="data_inicio"
                                label={t('Data de inicio')}
                                name="data_inicio"
                                mask="##/##/####"
                                component={InputMask}
                                error={<ErrorMessage name="data_inicio" />}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Field 
                                required
                                id="data_fim"
                                label={t('Data Fim')}
                                name="data_fim"
                                pattern="[0-9]*"
                                mask="##/##/####"
                                component={InputMask}
                                error={<ErrorMessage name="data_fim" />}
                            />
                        </Grid>
                    </Grid>
                    
                </>
                
            }
            
            <hr className='auto' />
            <Grid container spacing={2}>               
                <Grid item xs={12} md={12}>
                    <h6 className='line-title'>Período de Divulgação:</h6>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="data_inicio"
                        label={t('Data de inicio')}
                        name="data_inicio"
                        mask="##/##/####"
                        component={InputMask}
                        error={<ErrorMessage name="data_inicio" />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="data_fim"
                        label={t('Data Fim')}
                        name="data_fim"
                        pattern="[0-9]*"
                        mask="##/##/####"
                        component={InputMask}
                        error={<ErrorMessage name="data_fim" />}
                    />
                </Grid>
            </Grid>
                {/* alterção n° 29
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="houve_cobranca"
                        label={t('Houve cobrança?')}
                        name="houve_cobranca"
                        component={InputRadio}
                        list={[
                            { value: 'sim', label: t('Sim') },
                            { value: 'nao', label: t('Não') }
                        ]}
                        error={<ErrorMessage name="houve_cobranca" />}
                    />
                    </Grid>
                {props.values.houve_cobranca === 'sim' && 
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Field 
                                required
                                id="valor_cobranca"
                                label={t('Se sim, qual valor?')}
                                name="valor_cobranca"
                                component={InputTextFormat}
                                error={<ErrorMessage name="valor_cobranca" />}
                            />
                        </Grid>
                    </Grid>
                }
                    fim alteração n° 29*/}
            
            
        
            </>
    );
}