import React, { useContext } from 'react';
import * as Yup from 'yup';
import Api from '../../core/api';
import Util from '../../core/Util';
import { Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import AuthContext from '../../context/auth';
import { useTranslation } from 'react-i18next';

export default function Login() {

    const { setIsLogged } = useContext(AuthContext);
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (values, actions) => {
            const response = await Api('post', 'login', '', values);
            if(response) {
                Util.setToken(response.token);
                // Message.success(response.message);
                setTimeout(() => {
                    setIsLogged(true);
                    // Util.redirect();
                }, 1000);
                actions.setSubmitting(false);
            }
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email(t('E-mail inválido    ')).required(t('Campo obrigatório')),
            password: Yup.string().required(t('Campo obrigatório'))
        }),
    });
    


    return (
        <div id="login" style={{ background: '#FFF' }}>
            <Container component="main" maxWidth="xs">
                <div className="">
                    <CssBaseline>
                        <Box 
                            sx={{ 
                                marginTop: 8,
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center',
                                background: '#FFF',
                                border: '1px solid #CCC',
                                padding: 2,
                                borderRadius: '5px'
                            }}
                        >
                            
                            <Avatar
                                alt="Remy Sharp"
                                src="https://img.freepik.com/premium-vector/female-user-profile-avatar-is-woman-character-screen-saver-with-emotions_505620-617.jpg"
                                sx={{ width: 100, height: 100 }}
                            />
                            <Typography component="h1">App - Away</Typography>
                            <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField 
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="E-mail"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={e => formik.setFieldValue(e.target.name, e.target.value)}
                                    value={formik.values.email}
                                />
                                <span class="error-validation">{formik.errors.email}</span>
                                <TextField 
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="password"
                                    label={t('Senha')}
                                    name="password"
                                    type="password"
                                    autoComplete="password"
                                    autoFocus
                                    onChange={e => formik.setFieldValue(e.target.name, e.target.value)}
                                    value={formik.values.password}
                                />
                                <span class="error-validation">{formik.errors.password}</span> <br />
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Lembre-me"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {t('Acessar')}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2">
                                            Esqueceu sua senha?
                                        </Link>
                                    </Grid>
                                    {/* <Grid item>
                                        <Link href="#" variant="body2">
                                            Você n
                                        </Link>
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </Box>
                    </CssBaseline>
                </div>
            </Container>
        </div>
    );

}