import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shards-react';
import Api from '../../../core/api';
import Crud from '../../../components/Crud';
import { InputMask, InputRadio, InputText, Select, InputCheckbox, InputTextFormat, InputTextFormatNumber } from '../../../components/FormHelper/Input';
import SelectAutoComplete from '../../../components/FormHelper/SelectAutoComplete';

export default function MidiaDigital(props) {
    const { t } = useTranslation();
    const [parceiros, setParceiros] = useState([]);
    const [pautas, setPautas] = useState([]);

    async function getParceiros() {
        const response = await Api('get', 'parceiros/options', '', {});
        setParceiros(response.data);
    }

    async function getPautas() {
        const response = await Api('get', 'briefings/options', '', {});
        setPautas(response.data);
    }

    useEffect(() => {
        props.setFieldValue('post_instagram', '');
        props.setFieldValue('youtube_id', '');
    }, [props.values.tipo_conteudo]);

    useEffect(() => {
        getParceiros();
        getPautas();
    }, []);

    return (
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Field
                    required
                    id="parceiro_id"
                    label={t('Selecione o Parceiro')}
                    name="parceiro_id"
                    component={SelectAutoComplete}
                    endPoint="parceiros/options"
                    list={parceiros}
                    error={<ErrorMessage name="parceiro_id" />}
                />
            </Grid>
        </Grid>
        <hr className='auto' />
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <Field
                    required
                    id="tipo_conteudo"
                    label={t('Tipo de conteúdo:')}
                    name="tipo_conteudo"
                    column={4}
                    component={InputRadio}
                    list={[
                        { value: 'anuncio_website', label: t('Sites e perfis de empresas parceiras') },
                        { value: 'lista_whatsapp', label: t('Lista de WhatsApp') },
                        { value: 'influencer', label: t('Influencer') },
                        { value: 'newsletter', label: t('Newsletter') },
                        { value: 'reportagem', label: t('Reportagem') }
                    ]}
                    error={<ErrorMessage name="tipo_conteudo" />}
                />
            </Grid>
        </Grid> 
        <hr className='auto' />
        
        
       
        {props.values.tipo_conteudo === 'anuncio_website' && 
            <>
                <hr className='auto' />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <h6>
                            Tipo do anúncio:
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        
                        <Field 
                            id="banner_superior"
                            label={t("Banner superior Home Page")}
                            name="banner_superior"
                            component={InputCheckbox}
                            error={<ErrorMessage name="banner_superior" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            id="banner_lateral"
                            label={t("Banner lateral Home Page")}
                            name="banner_lateral"
                            component={InputCheckbox}
                            error={<ErrorMessage name="banner_lateral" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            id="banner_rodape"
                            label={t("Banner rodapé")}
                            name="banner_rodape"
                            component={InputCheckbox}
                            error={<ErrorMessage name="banner_rodape" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            id="post_instagram"
                            label={t("Post Instagram/Facebook")}
                            name="post_instagram"
                            component={InputCheckbox}
                            error={<ErrorMessage name="post_instagram" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            id="youtube_id"
                            label={t("Divulgação no Youtube")}
                            name="youtube_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="youtube_id" />}
                        />
                    </Grid>
                </Grid>
                <hr className='auto' />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="cliques_views"
                            label={t('Total de cliques/views:')}
                            name="cliques_views"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="cliques_views" />}
                        />
                    </Grid>            
                   
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h3>{t('Periodo de divulgação:')}</h3>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="data_inicio"
                            label={t('Data de Inicio')}
                            name="data_inicio"
                            mask="##/##/####"
                            component={InputMask}
                            error={<ErrorMessage name="data_inicio" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="data_fim"
                            label={t('Data Fim:')}
                            name="data_fim"
                            mask="##/##/####"
                            component={InputMask}
                            error={<ErrorMessage name="data_fim" />}
                        />
                    </Grid>
                </Grid>
            </>
        }

        {props.values.tipo_conteudo === 'influencer' && 
            <>
                <hr className='auto' />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <h6 className='line-title'>
                        Tipo do anúncio:
                        </h6>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            
                            id="live_entrevista"
                            label={t("Live com entrevista a diretores")}
                            name="live_entrevista"
                            component={InputCheckbox}
                            error={<ErrorMessage name="live_entrevista" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            
                            id="testimonial_id"
                            label={t("Testimonial/Divulgação")}
                            name="testimonial_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="testimonial_id" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            
                            id="post_instagram"
                            label={t("Post Instagram/Facebook")}
                            name="post_instagram"
                            component={InputCheckbox}
                            error={<ErrorMessage name="post_instagram" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            
                            id="reels_id"
                            label={t("Reels")}
                            name="reels_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="reels_id" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            id="stories_id"
                            label={t("Stories")}
                            name="stories_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="stories_id" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            id="youtube_id"
                            label={t("Divulgação no Youtube")}
                            name="youtube_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="youtube_id" />}
                        />
                    </Grid>
                </Grid>
                <hr className='auto' />
                <Grid container spacing={2}>
                    
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="insercoes_id"
                            label={t('Total de inserções:')}
                            name="insercoes_id"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="insercoes_id" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="pessoas_impactadas"
                            label={t('Total de pessoas impactadas:')}
                            name="pessoas_impactadas"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="pessoas_impactadas" />}
                        />
                    </Grid>
                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h6>{t('Período da ação/inserção:')}</h6>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="data_inicio"
                            label={t('Data de Inicio')}
                            name="data_inicio"
                            mask="##/##/####"
                            component={InputMask}
                            error={<ErrorMessage name="data_inicio" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="data_fim"
                            label={t('Data Fim')}
                            name="data_fim"
                            mask="##/##/####"
                            component={InputMask}
                            error={<ErrorMessage name="data_fim" />}
                        />
                    </Grid>
                </Grid>
            </>
        }

        {props.values.tipo_conteudo === 'reportagem' && 
            <>
                <hr className='auto' />
                <Grid container spacing={2}>
                    {/* <Grid item xs={12} md={12}>
                        <h6>
                        Selecione uma pauta:
                        </h6>
                    </Grid>
                     */}
                    <Grid item xs={12} md={12}>
                        <Field
                            required
                            id="pauta_id"
                            label={t('Selecione uma Pauta')}
                            name="pauta_id"
                            component={Select}
                            list={pautas}
                            error={<ErrorMessage name="pauta_id" />}
                        />
                    </Grid>
                </Grid>
                <hr className='auto' />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Field
                            required
                            id="entrevista_id"
                            label={t('Houve entrevista?')}
                            name="entrevista_id"
                            component={InputRadio}
                            list={[
                                { value: 'sim', label: t('Sim') },
                                { value: 'nao', label: t('Não') }
                            ]}
                            error={<ErrorMessage name="entrevista_id" />}
                        />
                    </Grid>
                </Grid>
                <hr className='auto' />
                <Grid container spacing={2}>  
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="alcance_id"
                            label={t('Total de pessoas alcançadas:')}
                            name="alcance_id"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="alcance_id" />}
                        />
                    </Grid>
                    
                    {/* <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="contatos_id"
                            label={t('Número de contatos:')}
                            name="contatos_id"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="contatos_id" />}
                        />
                    </Grid> */}
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <h6>{t('Período da ação/inserção:')}</h6>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="data_inicio"
                            label={t('Data de Inicio')}
                            name="data_inicio"
                            mask="##/##/####"
                            component={InputMask}
                            error={<ErrorMessage name="data_inicio" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="data_fim"
                            label={t('Data Fim')}
                            name="data_fim"
                            mask="##/##/####"
                            component={InputMask}
                            error={<ErrorMessage name="data_fim" />}
                        />
                    </Grid>
                </Grid>
            </>
        }

        {['lista_whatsapp', 'newsletter'].includes(props.values.tipo_conteudo) && 
            <>
                <hr className='auto' />
                <Grid container spacing={2}>           
                    <Grid item xs={12} md={12}>
                        <h6>
                            Nicho:
                        </h6>
                    </Grid>  
                    <Grid item xs={12} md={3}>
                        <Field 
                            required
                            id="pastores_id"
                            label={t("Pastores")}
                            name="pastores_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="pastores_id" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            required
                            id="lider_id"
                            label={t("Líderes em geral")}
                            name="lider_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="lider_id" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            required
                            id="membros_id"
                            label={t("Membros")}
                            name="membros_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="membros_id" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            required
                            id="empresarios_id"
                            label={t("Empresários")}
                            name="empresarios_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="empresarios_id" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Field 
                            required
                            id="catolica_id"
                            label={t("Igreja Católica")}
                            name="catolica_id"
                            component={InputCheckbox}
                            error={<ErrorMessage name="catolica_id" />}
                        />
                    </Grid>
                </Grid>
                <hr className='auto' />
               
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="quantidade_contatos"
                            label={t('Quantidade de contatos na lista')}
                            name="quantidade_contatos"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="quantidade_contatos" />}
                        />
                    </Grid>
                </Grid>
            </>
        }

        {['newsletter'].includes(props.values.tipo_conteudo) && 
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Field 
                            required
                            id="taxa_abertura"
                            label={t('Taxa de abertura %:')}
                            name="taxa_abertura"
                            pattern={"[0-9]*"}
                            component={InputText}
                            error={<ErrorMessage name="taxa_abertura" />}
                        />
                    </Grid>
                </Grid>
            </>
        }
        </>
    );
}