import axios from "axios";
import Message from "../Message";
import Util from '../Util';
import Pubsub from 'pubsub-js';

export default async function api(method, endPoint, controller, params, responseType = 'json') {
    Pubsub.publish('loading-screen', true);
    const baseURL = process.env.NODE_ENV === 'production' ? 
        'https://api.360wayup.com/api/' : 'http://localhost:8000/api/';

    const api = axios.create({
        baseURL: baseURL,
        params: {
            lang: localStorage.getItem("language") ? localStorage.getItem("language") : 'pt'
        },
        headers: {
            'Authorization': `Bearer ${Util.getToken()}`,
            'Content-Type': 'application/json',
        },
        responseType: responseType === 'json' ? responseType : 'blob'
        // onUploadProgress(progressEvent) {
        //     const percentCompleted = Math.round(
        //       (progressEvent.loaded * 100) / progressEvent.total
        //     );
        //     PubSub.publish('progress', percentCompleted);
        // },
        // onDownloadProgress(progressEvent) {
        //     const line = progressEvent.target.responseText.split('|');
        //     let row = line[line.length - 1];
        //     row = JSON.parse(row);
        //     let percentCompleted = Math.round((row.registros / row.total) * 100);
        //     // alert(percentCompleted);
        //     // console.log(percentCompleted);
        //     PubSub.publish('progress', {
        //         titulo: row.titulo ? row.titulo : '',
        //         registros: row.registros,
        //         total: row.total,
        //         progress: percentCompleted,
        //     });
        // },
        // onDownloadProgress(progressEvent) {},
    });

    api.interceptors.response.use(response => {
        Pubsub.publish('loading-screen', false);
        return response;
    }, error => {
        // Symfony
        // if(error.response) {
        //     if(error.response.status === 422) {
        //         Message.validation(error);
        //     } else {
        //         Message.error(error.response.data.message);
        //     }
        // }

        if(error.response) {
            if(error.response.status === 401) {
                Util.removeToken();
                Util.redirect();
            }
            if(error.response.status === 422) {
                Message.validation(error);
            } else {
                Message.error(error.response.data.message);
            }
        }
        Pubsub.publish('loading-screen', false);
        // return Promise.reject(error);
        return Promise.reject(error);
    });
    
    const resp = await api[method](endPoint + controller, params);
    
    
    if(method !== 'delete') {
        return resp.data;
    }
    return resp;
} 