import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import Tables from "./views/Tables";
import Empresas from "./views/Empresas";
import Login from "./views/Login";
import Parceiros from "./views/Parceiros";
import Users from "./views/Users";
import RamoAtividade from "./views/RamoAtividade";
import Acoes from "./views/Acoes";
import Briefings from "./views/Briefings";
import Eventos from "./views/Eventos";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/login",
    layout: null,
    component: Login
  },
  {
    path: "/home",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: Users
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/projetos",
    layout: DefaultLayout,
    component: Empresas
  },
  {
    path: "/acoes",
    layout: DefaultLayout,
    component: Acoes
  },
  {
    path: "/briefings",
    layout: DefaultLayout,
    component: Briefings
  },
  {
    path: "/eventos",
    layout: DefaultLayout,
    component: Acoes
  },
  {
    path: "/ramo-atividades",
    layout: DefaultLayout,
    component: RamoAtividade
  },
  {
    path: "/parceiros",
    layout: DefaultLayout,
    component: Parceiros
  }
];
