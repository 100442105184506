import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shards-react';
import Api from '../../../core/api';
import { InputMask, InputRadio, InputText, Select, InputCheckbox } from '../../../components/FormHelper/Input';
import UploadImages from '../UploadImages';
import SelectAutoComplete from '../../../components/FormHelper/SelectAutoComplete';
import { InputTextFormat } from 'src/components/FormHelper/InputML';

export default function VisitaIgrejas(props) {
    const { t } = useTranslation();
    const [igrejas, setIgrejas] = useState([]);

    async function getIgrejas() {
        const response = await Api('get', 'parceiros/options', '', {});
        setIgrejas(response.data);
    }

    useEffect(() => {
        getIgrejas();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="parceiro_id"
                        label={t('Igreja Parceira')}
                        name="parceiro_id"
                        component={SelectAutoComplete}
                        endPoint="parceiros/options"
                        list={igrejas}
                        error={<ErrorMessage name="parceiro_id" />}
                    />
                </Grid>
            </Grid>   
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="reuniao"
                        label={t('Qual reunião?')}
                        name="reuniao"
                        component={InputRadio}
                        list={[
                            { value: 'culto_principal', label: t('Culto Principal de domingo') },
                            { value: 'culto_jovem', label: t('Culto de Jovens') },
                            { value: 'reuniao_mulheres', label: t('Reunião das Mulheres') },
                            { value: 'reuniao_lideres', label: t('Reunião de Lideres (apenas desta igreja)') },
                            { value: 'escola_biblica', label: t('Escola Biblica Dominical') },
                            { value: 'reuniao_dos_homens', label: t('Reunião dos Homens') },
                            { value: 'outros_eventos', label: t('Outros Eventos') },
                        ]}
                        error={<ErrorMessage name="reuniao" />}
                    />
                </Grid>
            </Grid>
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="numero_cultos"
                        label={t('Nº cultos atendidos')}
                        name="numero_cultos"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="numero_cultos" />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="total_pessoas_impactadas"
                        label={t('Nº total de pessoas impactadas')}
                        name="total_pessoas_impactadas"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="total_pessoas_impactadas" />}
                    />
                </Grid>
            </Grid>
            
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <h6>Oportunidades:</h6>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="stage_mic"
                        label={t('Stage Mic')}
                        name="stage_mic"
                        component={InputCheckbox}
                        error={<ErrorMessage name="stage_mic" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exibicao_trailer"
                        label={t('Exibição de trailler')}
                        name="exibicao_trailer"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exibicao_trailer" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="qr_code_no_telao"
                        label={t('QR Code no telão')}
                        name="qr_code_no_telao"
                        component={InputCheckbox}
                        error={<ErrorMessage name="qr_code_no_telao" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="distribuicao_material"
                        label={t('Distribuição de Material')}
                        name="distribuicao_material"
                        component={InputCheckbox}
                        error={<ErrorMessage name="distribuicao_material" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exposicao_banner"
                        label={t('Exposição de Banner')}
                        name="exposicao_banner"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exposicao_banner" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="apresentacao_pastor"
                        label={t('Apresentação pelo pastor')}
                        name="apresentacao_pastor"
                        component={InputCheckbox}
                        error={<ErrorMessage name="apresentacao_pastor" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="exibicao_vt"
                        label={t('Exibição de VT')}
                        name="exibicao_vt"
                        component={InputCheckbox}
                        error={<ErrorMessage name="exibicao_vt" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="testemunhal"
                        label={t('Testemunhal')}
                        name="testemunhal"
                        component={InputCheckbox}
                        error={<ErrorMessage name="testemunhal" />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field 
                        id="panfletagem"
                        label={t('Panfletagem')}
                        name="panfletagem"
                        component={InputCheckbox}
                        error={<ErrorMessage name="panfletagem" />}
                    />
                </Grid>
            </Grid>
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="data_visita"
                        label={t('Data da Visita')}
                        name="data_visita"
                        mask="##/##/####"
                        component={InputMask}
                        error={<ErrorMessage name="data_visita" />}
                    />
                </Grid>
                
            </Grid>
            <hr className='auto' />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="havia_transmissao"
                        label={t('Havia Transmissão Online?')}
                        name="havia_transmissao"
                        component={InputRadio}
                        list={[
                            { value: 'sim', label: t('Sim') },
                            { value: 'nao', label: t('Não') }
                        ]}
                        error={<ErrorMessage name="havia_transmissao" />}
                    />
                </Grid>
                {props.values.havia_transmissao === 'sim' &&
                    <Grid item xs={12} md={6}>
                        <Field 
                            required
                            id="publico_online_total"
                            label={t('Público online total estimado')}
                            name="publico_online_total"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="publico_online_total" />}
                        />
                    </Grid>
                }
            </Grid>
            
            {/* CRISTA */}
            <Grid container spacing={props.values.autoridade_crista_presentes === 'sim' ? 2 : 0}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="autoridade_crista_presentes"
                        label={t('Lideranças cristãs presentes?')}
                        name="autoridade_crista_presentes"
                        component={InputRadio}
                        list={[
                            { value: 'sim', label: t('Sim') },
                            { value: 'nao', label: t('Não') }
                        ]}
                        error={<ErrorMessage name="autoridade_crista_presentes" />}
                    />
                </Grid>

                {props.values.autoridade_crista_presentes === 'sim' &&
                    <Grid item xs={12} md={6}>
                        <Field 
                            required
                            id="n_estimado_autoridades"
                            label={t('Nº Estimado de Autoridades')}
                            name="n_estimado_autoridades"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="n_estimado_autoridades" />}
                        />
                    </Grid>
                }
            </Grid>

            {/* POLITICOS */}
            <Grid container spacing={props.values.autoridade_politicas_presentes === 'sim' ? 2 : 0}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="autoridade_politicas_presentes"
                        label={t('Autoridades Políticas Presentes?')}
                        name="autoridade_politicas_presentes"
                        component={InputRadio}
                        list={[
                            { value: 'sim', label: t('Sim') },
                            { value: 'nao', label: t('Não') }
                        ]}
                        error={<ErrorMessage name="autoridade_politicas_presentes" />}
                    />
                </Grid>

                {props.values.autoridade_politicas_presentes === 'sim' &&
                    <Grid item xs={12} md={6}>
                        <Field 
                            required
                            id="n_estimado_politicos"
                            label={t('Nº Estimado de Políticos')}
                            name="n_estimado_politicos"
                            pattern="[0-9]*"
                            component={InputText}
                            error={<ErrorMessage name="n_estimado_politicos" />}
                        />
                    </Grid>
                }
            </Grid>

             
        </>
    );
}