import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';
import Api from '../../core/api';
import Form from '../Form';
import Grid from '../Grid';
import InputSearch from '../FormHelper/InputSearch';
import Message from '../../core/Message';
import useDebounce from '../useDebounce';
import { Button, Card, CardBody, Col, Container, Row } from 'shards-react';
import PageTitle from '../common/PageTitle';
import { useTranslation } from 'react-i18next';
export default function Crud(props) {

    const { t } = useTranslation();

    const [view, setView] = useState('list');
    const [data, setData] = useState(props.emptyObject);
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('');
    const [paramsSearch, setParamsSearch] = useState({});
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);
    const debouncedSearchTerm = useDebounce(search, 500);
    const debouncedSearchParamsTerm = useDebounce(paramsSearch, 500);


    async function handleSubmit(values, objects) {
        let msg = t('Registro Salvo com Sucesso');

        if (values.id) {
            msg = t('Registro Atualizado com Sucesso');
            await Api('put', props.endPoint, `/${values.id}`, values);
        } else {
            const response = await Api('post', props.endPoint, '', values);
            setData(response.data)
        }
        // objects.setSubmi
        Message.success(msg);
        loadData();
        
        if (!props.saveContinueForm) {
            setView('list');
        } else {
            if(view === 'new') {
                setView('edit');
            } else {
                setView('list');
            }
            
        }
        // setView('list');
    }

    function handleNew() {
        setData(props.emptyObject);
        setView('new');
    }

    async function handleEdit(item) {
        const response = await Api('get', props.endPoint, `/${item.id}`, {});
        setData({ ...response.data });
        setView('edit');
    }

    async function handleDelete(item) {
        const check = await Message.confirmation(t("Deseja deletar este registro?"));
        if (check) {
            await Api('delete', props.endPoint, `/${item.id}`, {});
            loadData();
            Message.success(t("Registro deletado com sucesso."));
        }
    }

    function handleList(item) {
        setView('list');

    }

    async function loadData(e = null) {
        if (e) {
            e.preventDefault();
        }
        let params = {
            page: page,
            ...paramsSearch
        };

        if (props.searchFieldName && search) {
            params[props.searchFieldName] = search;
        }

        const response = await Api('get', props.endPoint, '', {
            params,

        });
        //setList(response.data);
        setList(response.data);
        setPagination(response);
    }

    useEffect(() => {
        loadData();
    }, [page]);

    // useEffect(() => {
    //     if (search === '') {
    //         loadData();
    //     }
    // }, [search]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            loadData();
        }
    }, [debouncedSearchTerm]);

    // useEffect(() => {
    //     if (debouncedSearchParamsTerm) {
    //         loadData();
    //     }
    // }, [debouncedSearchParamsTerm]);


    return (
        <>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle sm="4" title={props.title} subtitle={props.subtitle} className="text-sm-left" />
                </Row>
                <Card small className="mb-4">
                    <CardBody>
                        {view === 'list' ?
                            <Row>
                                <Col xs={10} md={6}>
                                    <InputSearch
                                        value={search}
                                        handleText={value => setSearch(value)}
                                        loadData={loadData}
                                    />
                                </Col>
                                <Col xs={2} md={6}>
                                    {props.enableBtnNew && <Button size="sm" style={{ float: 'right', marginBottom: '20px' }} theme="success" onClick={handleNew}>{t('Novo')}</Button>}
                                </Col>
                            </Row>
                            : null
                        }
                        {view === 'list' &&
                            <>
                                {props.FormSearch && <props.FormSearch params={paramsSearch} setParams={setParamsSearch} />}
                                <Grid
                                    {...props}
                                    list={list}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                />
                                <Pagination
                                    changePage={data => setPage(data.page)}
                                    data={pagination}
                                />
                            </>
                        }
                        {view === 'new' || view === 'edit' ?
                            <Form
                                {...props}
                                view={view}
                                emptyObject={data}
                                handleSubmit={handleSubmit}
                                handleCancel={handleList}
                                handleEdit={handleEdit}
                                loadData={loadData}
                            /> : null}

                        {/* <Card.Footer>
                            Footer
                        </Card.Footer> */}
                    </CardBody>
                </Card>
            </Container>
        </>
    );

}

Crud.defaultProps = {
    enableBtnNew: true,
    saveContinueForm: false,
    FormSearch: null
};