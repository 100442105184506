import React from 'react';
import { Grid } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputRadio, InputText } from '../../../components/FormHelper/Input';
import SelectCidades from '../../../components/FormHelper/SelectCidades';
import SelectEstados from '../../../components/FormHelper/SelectEstados';

export default function Igreja(props) {
    const { t } = useTranslation();
    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="nome_igreja"
                        label={t('Nome da Igreja')}
                        
                        name="nome_igreja"
                        component={InputText}
                        error={<ErrorMessage name="nome_igreja" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="numero_membros"
                        label={t('Nº de Membros')}
                        name="numero_membros"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="numero_membros" />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Field 
                        required
                        id="numero_templos"
                        label={t('Nº de Templos')}
                        name="numero_templos"
                        pattern="[0-9]*"
                        component={InputText}
                        error={<ErrorMessage name="numero_templos" />}
                    />
                </Grid>
            </Grid>
           
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="contato_parceiro"
                        label={t('Contato do Parceiro')}
                        name="contato_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="contato_parceiro" />}
                    />
                </Grid>
                
                <Grid item xs={12} md={12}>
                    <Field
                        required
                        id="cargo_contato_parceiro"
                        label={t('Cargo do Contato no Parceiro')}
                        name="cargo_contato_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="cargo_contato_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Field 
                        required
                        id="telefone_parceiro"
                        label={t('Telefone')}
                        name="telefone_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="telefone_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Field 
                        id="email_parceiro"
                        label={t('E-mail')}
                        name="email_parceiro"
                        component={InputText}
                        error={<ErrorMessage name="email_parceiro" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="estado_parceiro"
                        label={t('Estados')}
                        name="estado_parceiro"
                        cityName="cidade_parceiro"
                        component={SelectEstados}
                        list={[]}
                        error={<ErrorMessage name="estado_parceiro" />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field 
                        required
                        id="cidade_parceiro"
                        label={t('Cidade')}
                        name="cidade_parceiro"
                        component={SelectCidades}
                        estado={props.values.estado_parceiro}
                        error={<ErrorMessage name="cidade_parceiro" />}
                    />
                </Grid>
            </Grid>
        </>
    );
}