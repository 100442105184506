import React, { useEffect, useState } from 'react';
import{ Row, Col, Card, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FileUpload, Button, InputText } from 'src/components/FormHelper/Input';
import { ErrorMessage, Field, Formik } from 'formik';
import { GrFormClose } from 'react-icons/gr';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Pubsub from 'pubsub-js';

import Api from '../../../core/api';
import Message from '../../../core/Message';
import { Modal, ModalBody, ModalHeader } from 'shards-react';
import { Grid } from '@mui/material';


export default function UploadImagesOriginal({ id, endPoint, loadData }) {

    const [listImages, setListImages] = useState([]);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imagesSelected, setImagesSelected] = useState([]);
    const [show, setShow] = useState(false);

    async function getListImages() {
        Pubsub.publish('loading-screen', true);
        const response = await Api('get', `list-images/`+id, '', {});        
        setListImages([...response.data]);
        if(id && response.data.length === 0) {
            setShow(true);
        }
        Pubsub.publish('loading-screen', false);
    }

    async function submitImage(values) {
        let formData = new FormData();
        formData.append('image', file);
        formData.append('acao_id', id);
        formData.append('legenda_pt', values.legenda_pt);
        formData.append('legenda_en', values.legenda_en);

        await Api('post', `upload-images`, '', formData);        
        getListImages();
        setShow(false);
        Message.success("Imagem Carregada com Sucesso.");
        loadData();
    }

    async function removeImage(item) {
        let check = await Message.confirmation("Deseja remover está imagem?");
        if(check) {
            if(item.local === 'adverts') {
                await Api('delete', `delete-images/`+item.id, '', {});        
            } else {
                await Api('delete', `delete-images/`+item.id, '', {});        
            }
            Message.success("Imagem Deletada com Sucesso.");
            setListImages([...listImages.filter(i => i.id !== item.id)]);
        }
        if(loadData) {
            loadData();
        }
    }

   

    function selectImage(item) {
        if(imagesSelected.includes(item.id)) {
            let list = imagesSelected.filter(i => i !== item.id);
            setImagesSelected([...list]);
        } else {
            let list = imagesSelected;
            list.push(item.id);
            setImagesSelected([...list]);
        }
    }

    function handleFile(e) {
        const files = e.target.files;
        if(files.length) {
            setFile(files[0]);
        }
        loadData();
    }

    useEffect(() => {
        getListImages();
        
        
    }, []);

    return (
        <>
            <Button onClick={() => setShow(true)} name="Nova Imagem" variant="success" />
            <Modal open={show} toggle={() => setShow(!show)}>
                <ModalHeader>
                    Upload de Imagem
                </ModalHeader>
                <ModalBody>
                  
                    <Row>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                images: [],
                                legenda_pt: '',
                                legenda_en: '',
                            }}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {
                                    submitImage(values)
                                    actions.resetForm();
                                    actions.setSubmitting(false);
                                }, 1000);
                            }}
                        >
                            {props => (
                                <form onSubmit={props.handleSubmit}>
                                    <Grid container> 
                                        <Grid xs={12}>
                                            <Field
                                                label="Imagem"
                                                type="file"
                                                id="images"
                                                name="images"
                                                handleFile={handleFile}
                                                component={FileUpload}
                                            />
                                            <small>Máximo de 4mb</small>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Field 
                                                required
                                                id="legenda_pt"
                                                label={'Legenda PT'}
                                                name="legenda_pt"
                                                component={InputText}
                                                error={<ErrorMessage name="legenda_pt" />}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Field 
                                                required
                                                id="legenda_en"
                                                label={'Legenda EN'}
                                                name="legenda_en"
                                                component={InputText}
                                                error={<ErrorMessage name="legenda_en" />}
                                            />
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <div style={{ float: 'right' }}>
                                        <Button onClick={props.handleSubmit} name="Enviar" variant="success" />
                                    </div>
                                </form>
                            )}

                        </Formik>
                    </Row>
                    {/* <Row>
                        <Col md={2}>
                            <Button onClick={submitImage} name="Enviar" variant="success" />
                        </Col>
                    </Row> */}
                </ModalBody>
            </Modal>
            
            <br />
            <hr />
            <Row> 
                {listImages.map((item, idx) => (
                    <Col md={3} key={idx} onClick={() => selectImage(item)} style={{ background: imagesSelected.includes(item.id) ? 'white' : 'white' }}>
                        <Card style={{}}>
                            <Card.Img variant="top" src={item.path} />
                            <Card.Body >
                                <div style={{ textAlign: 'right' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href onClick={() => removeImage(item)} style={{ color: 'red' }}>
                                        <GrFormClose size="30px" color="orange" />
                                    </a>
                                </div>
                                <div>
                                    PT: <p>{item.legenda_pt}</p>
                                    EN: <p>{item.legenda_en}</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
}

UploadImagesOriginal.defaultProps = {
    loadData: () => {},
};