import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Crud from '../../components/Crud';
import { InputText, Select } from '../../components/FormHelper/Input';


const FormWrapper = ({ Field, ErrorMessage }) => {
    

    return (
        <>
            <Row>
                <Col>
                    <Field 
                        required
                        id="name"
                        label="Nome"
                        name="name"
                        component={InputText}
                        error={<ErrorMessage name="name" />}
                    />
                </Col>
                <Col>
                    <Field 
                        required
                        id="email"
                        label="E-mail"
                        name="email"
                        component={InputText}
                        error={<ErrorMessage name="email" />}
                    />
                </Col>
            </Row>            
            <Row>
                <Col md={4}>
                    <Field 
                        required
                        id="role"
                        label="Permissão"
                        name="role"
                        placeholder="Selecione"
                        list={[
                            { value: '1', label: 'Admin' },
                            { value: '2', label: 'Assessor' },
                            { value: '3', label: 'Colaborador' },
                            { value: '4', label: 'Cliente' }
                        ]}
                        component={Select}
                        error={<ErrorMessage name="role" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="password"
                        label="Senha"
                        name="password"
                        type="password"
                        component={InputText}
                        error={<ErrorMessage name="password" />}
                    />
                </Col>
                <Col md={4}>
                    <Field 
                        required
                        id="confirm_password"
                        label="Confirmação de Senha"
                        name="confirm_password"
                        type="password"
                        component={InputText}
                        error={<ErrorMessage name="confirm_password" />}
                    />
                </Col>
            </Row>
            
        </>
    );
}

export default function Users() {
    return (
        <Crud 
            title="Usuários"
            subtitle="Cadastros"
            endPoint="users"
            emptyObject={{
                name: '',
                email: '',
                password: '',
                confirm_password: '',
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'name', label: 'Nome' },
                { name: 'email', label: 'E-mail' }
            ]}
            validation={(yup) => {
                return {
                    email: yup.string().email('E-mail inválido').required("Campo obrigatório"),
                    name: yup.string().required("Campo obrigatório"),
                    password: yup.string(),
                    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'As senhas são diferentes.'),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
