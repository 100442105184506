import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'shards-react';
import Crud from '../../components/Crud';
import { InputText } from '../../components/FormHelper/Input';
// import Api from 'src/core/api';

const FormWrapper = ({ Field, ErrorMessage }) => {
    const { t } = useTranslation();
    return (
        <>
            <Row container spacing={2}>
                <Col item sx={12} md={2}>
                    <Field 
                        id="id"
                        label={t('Codigo')}
                        name="id"
                        component={InputText}
                        disabled
                        error={<ErrorMessage name="id" />}
                    />
                </Col>
                <Col item sx={12} md={10}>
                    <Field 
                        required
                        id="nome"
                        label={t('Nome')}
                        name="nome"
                        component={InputText}
                        error={<ErrorMessage name="nome" />}
                    />
                </Col>
            </Row>            
        </>
    );
}

export default function RamoAtividade() {

    const { t } = useTranslation();

    return (
        <Crud 
            title={t('Ramo de Atividade')}
            subtitle={t('Cadastros')}
            endPoint="ramo_atividade"
            searchFieldName="search"
            emptyObject={{
                name: ''
            }}
            fields={[
                { name: 'id', label: 'Id', classBody: 'min-width' },
                { name: 'nome', label: t('Nome') }
            ]}
            validation={(yup) => {
                return {
                    nome: yup.string().required(t('Campo obrigatorio')),
                };
            }}
            FormWrapper={FormWrapper}
        />
    );
}
